import React, { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TablePagination from '@material-ui/core/TablePagination';

import { format } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  minWidth: 650,
}));

function ShowGreyhoundRace() {
  const classes = useStyles();
  const history = useHistory();
  const [race, setRace] = useState(null);
  const { external_id } = useParams();
  const [loading, setLoading] = useState(false);

  const fetchRace = () => {
    setLoading(true);
    api.get(`greyhound/races/${external_id}`).then((response) => {
      response.data.collects.forEach((collect) => collect.runners.reverse());
      setRace(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchRace();
  }, []);

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        {loading || !race ? (
          <p>Carregando...</p>
        ) : (
          <>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              <span style={{ color: 'gray' }}>{race.track.locality.name}</span>{' '}
              <br />
              <strong>
                {race.track.name} -{' '}
                {format(new Date(race.start_date), "dd/MM/yyyy - HH:mm'h'")}
              </strong>
            </Typography>
            {race.result ? (
              <Box>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan="5"
                          style={{
                            backgroundColor: '#f0f0f0',
                          }}
                        >
                          <strong>Resultado</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {race.result.runners.map((runner) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={runner.placing}
                        >
                          <TableCell>{runner.placing}°</TableCell>
                          <TableCell>Trap {runner.trap}</TableCell>
                          <TableCell>{runner.name}</TableCell>
                          <TableCell>{runner.odd} odd</TableCell>
                        </TableRow>
                      ))}

                      <TableRow>
                        <TableCell
                          style={{
                            backgroundColor: '#f0f0f0',
                          }}
                          colSpan="2"
                        >
                          <strong>Previsão</strong>
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: '#f0f0f0',
                          }}
                          colSpan="2"
                        >
                          <strong>Tricast</strong>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell width="25%">
                          {race.result.prevision}
                        </TableCell>
                        <TableCell width="25%">
                          {race.result.prevision_dividend} odd
                        </TableCell>
                        <TableCell width="25%">{race.result.tricast}</TableCell>
                        <TableCell width="25%">
                          {race.result.tricast_dividend} odd
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              <h2>Coleta de resultado ainda não concluída</h2>
            )}

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box mt={4}>
                  <h2>
                    Coleta de abertura -&nbsp;
                    {format(new Date(race.collects[0].createdAt), "HH:mm'h'")}
                  </h2>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            colSpan="3"
                            style={{
                              backgroundColor: '#f0f0f0',
                            }}
                          >
                            <strong>Aposta Vencedora</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {race.collects[0].runners.map((runner) => (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={runner.id}
                          >
                            <TableCell>Trap {runner.trap}</TableCell>
                            <TableCell align="right">{runner.odd}</TableCell>
                            <TableCell align="left">odd</TableCell>
                          </TableRow>
                        ))}

                        <TableRow>
                          <TableCell
                            style={{
                              backgroundColor: '#f0f0f0',
                            }}
                            colSpan="4"
                          >
                            <strong>Projeção de Tricast</strong>
                          </TableCell>
                        </TableRow>

                        {race.collects[0].projections.map((projection) => (
                          <TableRow key={projection.id}>
                            <TableCell>{projection.combination}</TableCell>
                            <TableCell align="right">
                              {projection.odd}
                            </TableCell>
                            <TableCell align="left">odd</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mt={4}>
                  <h2>
                    Coleta de fechamento -&nbsp;
                    {format(new Date(race.collects[0].createdAt), "HH:mm'h'")}
                  </h2>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            colSpan="3"
                            style={{
                              backgroundColor: '#f0f0f0',
                            }}
                          >
                            <strong>Aposta Vencedora</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {race.collects[0].runners.map((runner) => (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={runner.id}
                          >
                            <TableCell>Trap {runner.trap}</TableCell>
                            <TableCell align="right">{runner.odd}</TableCell>
                            <TableCell align="left">odd</TableCell>
                          </TableRow>
                        ))}

                        <TableRow>
                          <TableCell
                            style={{
                              backgroundColor: '#f0f0f0',
                            }}
                            colSpan="4"
                          >
                            <strong>Projeção de Tricast</strong>
                          </TableCell>
                        </TableRow>

                        {race.collects[0].projections.map((projection) => (
                          <TableRow key={projection.id}>
                            <TableCell>{projection.combination}</TableCell>
                            <TableCell align="right">
                              {projection.odd}
                            </TableCell>
                            <TableCell align="left">odd</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
}

export default ShowGreyhoundRace;
