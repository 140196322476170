import React, { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from '../../services/api';

const columns = [
  { id: 'id', label: '#' },
  {
    id: 'name',
    label: 'Nome',
  },
  {
    id: 'path',
    label: 'Path',
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function Paths() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [path, setPath] = useState(null);
  const [nameFilter, setNameFilter] = useState('');
  const [pathFilter, setPathFilter] = useState('');
  const [pathType, setPathType] = useState('none');
  const [coinValue, setCoinValue] = useState('none');
  const [paymentProportion, setPaymentProportion] = useState('none');
  const [numbers, setNumbers] = useState('');

  async function handleFilter() {
    const { data } = await api.get('admin/paths', {
      params: {
        name: nameFilter,
        path: pathFilter,
      },
    });
    setRows(data);
  }

  function fetchPaths() {
    api.get('admin/paths').then((response) => {
      const sorted = response.data.sort((a, b) => b.id - a.id);
      setRows(sorted);
    });
  }

  useEffect(() => {
    fetchPaths();
  }, []);

  function toggleModal() {
    setOpen(!open);
  }

  async function handleSave() {
    if (!name || !path) {
      // eslint-disable-next-line
      alert('Todos os campos são obrigatórios');
      return;
    }

    const payload = {
      name,
      path,
      coin_value: coinValue,
      payment: paymentProportion,
      numbers,
    };

    if (id) {
      await api.put(`admin/paths/${id}`, payload);
    } else {
      await api.post('admin/paths', payload);
    }

    setId(null);
    setName(null);
    setPath(null);

    toggleModal();
    fetchPaths();
    setSnackbarOpen(true);
  }

  function createNewPath() {
    setId(null);
    setName(null);
    setPath(null);
    toggleModal();
  }

  function handleCellClick(data) {
    setId(data.id);
    setName(data.name);
    setPath(data.path);
    setPathType(data.type);
    setCoinValue(data.coin_value);
    setPaymentProportion(data.payment);
    setNumbers(data.numbers);
    toggleModal();
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Operação efetuada com sucesso"
      />
      <Dialog
        open={open}
        onClose={toggleModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Path</DialogTitle>
        <DialogContent>
          <DialogContentText>Informe os dados do Path</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome do path"
            type="text"
            fullWidth
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            multiline
            margin="dense"
            id="path"
            label="Path"
            type="text"
            fullWidth
            defaultValue={path}
            onChange={(e) => setPath(e.target.value)}
          />
          <FormControl fullWidth style={{ marginTop: '20px' }}>
            <InputLabel>Tipo do path</InputLabel>
            <Select
              defaultValue={pathType}
              onChange={(e) => setPathType(e.target.value)}
            >
              <MenuItem value="none">Selecione...</MenuItem>
              <MenuItem value="bet">Path de jogada</MenuItem>
              <MenuItem value="coin">Path de moeda</MenuItem>
            </Select>
          </FormControl>
          {pathType === 'coin' && (
            <FormControl fullWidth style={{ marginTop: '20px' }}>
              <InputLabel>Valor da moeda</InputLabel>
              <Select
                defaultValue={coinValue}
                onChange={(e) => setCoinValue(e.target.value)}
              >
                <MenuItem value="none">Selecione...</MenuItem>
                <MenuItem value="50">R$ 0,50</MenuItem>
                <MenuItem value="100">R$ 1,00</MenuItem>
                <MenuItem value="200">R$ 2,00</MenuItem>
                <MenuItem value="500">R$ 5,00</MenuItem>
                <MenuItem value="2000">R$ 20,00</MenuItem>
                <MenuItem value="5000">R$ 50,00</MenuItem>
                <MenuItem value="10000">R$ 100,00</MenuItem>
                <MenuItem value="50000">R$ 500,00</MenuItem>
                <MenuItem value="200000">R$ 2.000</MenuItem>
                <MenuItem value="500000">R$ 5.000</MenuItem>
              </Select>
            </FormControl>
          )}
          {pathType === 'bet' && (
            <>
              <FormControl fullWidth style={{ marginTop: '20px' }}>
                <InputLabel>Proporção de pagamento</InputLabel>
                <Select
                  defaultValue={paymentProportion}
                  onChange={(e) => setPaymentProportion(e.target.value)}
                >
                  <MenuItem value="none">Selecione...</MenuItem>
                  <MenuItem value="1">1:1</MenuItem>
                  <MenuItem value="2">2:1</MenuItem>
                  <MenuItem value="5">5:1</MenuItem>
                  <MenuItem value="8">8:1</MenuItem>
                  <MenuItem value="11">11:1</MenuItem>
                  <MenuItem value="17">17:1</MenuItem>
                  <MenuItem value="35">35:1</MenuItem>
                </Select>
              </FormControl>
              <TextField
                multiline
                margin="dense"
                id="numbers"
                label="Números"
                type="text"
                fullWidth
                defaultValue={numbers}
                onChange={(e) => setNumbers(e.target.value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
        Listagem de paths
      </Typography>
      <Box>
        <Button variant="contained" color="primary" onClick={createNewPath}>
          Criar novo path
        </Button>
      </Box>

      <Box mt={4}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <TextField
                label="Nome"
                fullWidth
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Path"
                fullWidth
                value={pathFilter}
                onChange={(e) => setPathFilter(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Box mt={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFilter}
                >
                  Filtrar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box mt={5}>
        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          onClick={() => handleCellClick(row)}
                        >
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Container>
  );
}

export default Paths;
