import * as Yup from 'yup';

export const schema = Yup.object().shape({
  platform: Yup.string().required('Obrigatório'),
  type: Yup.string().required('Obrigatório'),
  login: Yup.string().required('Obrigatório'),
  password: Yup.string().required('Obrigatório'),
  host_name: Yup.string(),
  betfair_api_key: Yup.string().required('Obrigatório'),
  strategy_id: Yup.string().required('Obrigatório'),
});
