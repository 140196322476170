import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup.string().required('Obrigatório'),
  status: Yup.string().required('Obrigatório'),
  stripe_product_id: Yup.string(),
  membership_fee: Yup.string().required('Obrigatório'),
  fixed_cost: Yup.string().required('Obrigatório'),
  percent_upon_profit: Yup.string().required('Obrigatório'),
  gain_goal: Yup.string().nullable(),
  monthly_gain_goal: Yup.string().nullable(),
  loss_limit: Yup.string().nullable(),
  monthly_loss_limit: Yup.string().nullable(),
  recommended_capital: Yup.string().nullable().required('Obrigatório'),
  minimum_days: Yup.string().required('Obrigatório'),
  start_hour: Yup.string(),
  end_hour: Yup.string(),
  sequence_strategy_odd: Yup.string().required('Obrigatório'),
  entry: Yup.string().required('Obrigatório'),
  goes_to: Yup.string().required('Obrigatório'),
  martingales: Yup.array().of(
    Yup.object().shape({
      order: Yup.number().required('Obrigatório'),
      odd: Yup.string().required('Obrigatório'),
      amount: Yup.string().required('Obrigatório'),
    })
  ),
});
