import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { TextField } from '@material-ui/core';

export default function Input({ name, ...rest }) { // eslint-disable-line
  const inputRef = useRef(null);
  const materialInputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => ref.current.value,
      setValue: (ref, value) => {
        ref.current.value = value;

        materialInputRef.current
          .querySelector('label')
          .classList.add('MuiFormLabel-filled', 'MuiInputLabel-shrink');
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);
  return (
    <TextField
      id={fieldName}
      ref={materialInputRef}
      inputRef={inputRef}
      defaultValue={defaultValue}
      error={!!error}
      helperText={error && error}
      name={fieldName}
      {...rest} // eslint-disable-line
    />
  );
}
