import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => { // eslint-disable-line
  const [data, setData] = useState(() => { // eslint-disable-line
    const niltonId = '98356782';
    const carlosId = 'CarlosSid@9';

    const token = localStorage.getItem('@fbadmintoken_2');

    if (!token) {
      let id = prompt('Informe o seu login'); // eslint-disable-line

      let done = id && (id === niltonId || id === carlosId);

      while (!done) {
        id = prompt('Informe o seu login'); // eslint-disable-line
        done = id && (id === niltonId || id === carlosId);
      }

      localStorage.setItem('@fbadmintoken_2', id);
    }

    let profile;

    if (token === niltonId) {
      profile = 'master';
    } else if (token === carlosId) {
      profile = 'master';
    }

    return { user: { id: token, profile } };
  });

  return (
    <AuthContext.Provider value={{ user: data.user }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(' useAuth must be used within an authProvider ');
  }
  return context;
}
export { AuthProvider, useAuth };
