import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { TextField } from '@material-ui/core';
// import CurrencyFormat from 'react-currency-format';

function toNumber(value) {
  return parseFloat(value.replace(/\./g, '').replace(',', '.'));
}

export default function CurrencyInput({ name, ...rest }) { // eslint-disable-line
  const inputRef = useRef(null);
  const materialInputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        if (ref && ref.current && ref.current.value) {
          return toNumber(ref.current.value);
        }
        return null;
      },
      setValue: (ref, value) => {
        if (!value) value = 0;
        ref.current.value = value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
        });
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);
  return (
    <TextField
      customInput={TextField}
      id={fieldName}
      ref={materialInputRef}
      inputRef={inputRef}
      defaultValue={defaultValue}
      error={!!error}
      helperText={error && error}
      name={fieldName}
      {...rest} // eslint-disable-line
    />
  );
}
