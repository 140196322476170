const isEmpty = (value) =>
  // null or undefined
  value == null ||
  // has length and it's zero

  (value.hasOwnProperty('length') && value.length === 0) || //eslint-disable-line
  // is an Object and has no keys
  (value.constructor === Object && Object.keys(value).length === 0);

const getTotalFromPlay = (rows, entry, end) => {
  const range = (min, max) =>
    [...Array(max - min + 1).keys()].map((i) => i + min);

  const plays = range(entry, end - 1);

  const sum = (a, b) => a + b;

  const getTotal = (play, attribute) =>
    rows
      .map(
        (result) =>
          result.plays.find((resultPlay) => resultPlay.key === play)[attribute]
      )
      .reduce(sum, 0);

  return plays.map((play) => ({
    play,
    totalEvents: getTotal(play, 'value'),
    total: getTotal(play, 'amount'),
  }));
};

export { getTotalFromPlay };
