import React, { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import api from '../../../services/api';

const columns = [
  { id: 'id', label: '#' },
  {
    id: 'name',
    label: 'Nome',
    minWidth: 700,
  },
  {
    id: 'minimum_capital',
    label: 'Capital Mínimo',
    format: (value) =>
      (value / 100).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
  },
  {
    id: 'recommended_capital',
    label: 'Capital Recomendado',
    format: (value) =>
      ((value || 0) / 100).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const strategyStatus = [
  {
    id: 0,
    description: 'in_operation',
  },
  {
    id: 1,
    description: 'validated',
  },
  {
    id: 2,
    description: 'archived',
  },
  {
    id: 3,
    description: 'template',
  },
];

function Strategies() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);

  function fetchStrategies() {
    api
      .get('betfair/greyhound/strategies', {
        params: {
          status: strategyStatus[tabValue].description,
        },
      })
      .then((response) => setRows(response.data));
  }

  useEffect(() => {
    fetchStrategies();
  }, []);

  useEffect(() => {
    fetchStrategies();
  }, [tabValue]);

  function handleEdit(id) {
    history.push(`/betfair/greyhounds/strategy/form/${id}?newVersion=true`);
  }

  function handleDuplicate(id) {
    history.push(`/betfair/greyhounds/strategy/form/${id}?duplicate=true`);
  }

  function createStrategy() {
    history.push(`/betfair/greyhounds/strategy/form`);
  }

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
        Listagem de estratégias
      </Typography>
      <Box>
        <Button variant="contained" color="primary" onClick={createStrategy}>
          Criar nova stratégia
        </Button>
      </Box>
      <Box mt={2}>
        <Paper className={classes.root}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Em operação" />
            <Tab label="Validadas" />
            <Tab label="Arquivadas" />
            <Tab label="Estratégias Modelo" />
          </Tabs>
        </Paper>
      </Box>
      <Box mt={1}>
        {rows.length > 0 && (
          <Paper>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell align="right">
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <>
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                {...bindTrigger(popupState)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem onClick={() => handleEdit(row.id)}>
                                  Editar
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handleDuplicate(row.id)}
                                >
                                  Duplicar
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Box>
    </Container>
  );
}

export default Strategies;
