import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import { Select } from 'unform-material-ui';
import * as Yup from 'yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import Input from '../../components/Input';
import api from '../../services/api';
import { schema } from './schema';
import CurrencyInput from '../../components/CurrencyInput';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function StrategyForm() {
  const formRef = useRef(null);
  const classes = useStyles();
  const [playSteps, setPlaySteps] = useState([]);
  const [martingales, setMartingales] = useState([]);
  const [validated, setValidated] = useState(false);
  const [archived, setArchived] = useState(false);
  const [template, setTemplate] = useState(false);
  const [percentUponGainGoal, setPercentUponGainGoal] = useState(0);
  const [minimumCapital, setMinimumCapital] = useState(0);
  const [paths, setPaths] = useState([]); //eslint-disable-line
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  function calculateMinimumCapital() {
    const {
      base_bet_amount,
      martingales: currentMartingales,
    } = formRef.current.getData();

    let amountMartingalesBet = 0;
    let amountBase = 0;

    if (base_bet_amount) {
      amountBase = base_bet_amount;
    }

    if (currentMartingales && currentMartingales.length > 0) {
      amountMartingalesBet = currentMartingales
        .map((m) => m.base_bet_amount || 0)
        .reduce((a, b) => a + b, 0);
    }

    return setMinimumCapital(amountBase + amountMartingalesBet);
  }

  useEffect(() => {
    api.get('admin/paths').then((response) => {
      setPaths(response.data);

      if (id) {
        api.get(`admin/strategies/${id}`).then((strategyResponse) => { // eslint-disable-line
          const strategy = {
            ...strategyResponse.data,
            best_scenario: strategyResponse.data.best_scenario / 100,
            worst_scenario: strategyResponse.data.worst_scenario / 100,
            expected_scenario: strategyResponse.data.expected_scenario / 100,
            robot_cost: strategyResponse.data.robot_cost / 100,
            infra_cost: strategyResponse.data.infra_cost / 100,
            minimum_capital: strategyResponse.data.minimum_capital / 100,
            percent_upon_profit:
              strategyResponse.data.percent_upon_profit / 100,
            gain_goal: strategyResponse.data.gain_goal / 100,
            recommended_capital:
              strategyResponse.data.recommended_capital / 100,
            delay: strategyResponse.data.delay || 0,
          };

          const sortedMartingales = strategyResponse.data.martingales.sort(
            (a, b) => a.order - b.order
          );

          const sortedMartingalesWithSortedSteps = sortedMartingales.map(
            (martingale) => ({
              ...martingale,
              steps: martingale.steps.sort((a, b) => a.order - b.order),
            })
          );

          const steps = strategyResponse.data.steps.sort(
            (a, b) => a.order - b.order
          );

          setPlaySteps(steps);

          setMartingales(sortedMartingalesWithSortedSteps);

          formRef.current.setData(strategy);

          setValidated(strategyResponse.data.validated);
          setArchived(strategyResponse.data.archived);
          setTemplate(strategyResponse.data.template);
        });
      } else {
        setPlaySteps([
          {
            order: 0,
            id: (Math.random() * 10000000).toFixed(0),
          },
        ]);
        setMartingales([
          {
            order: 0,
            steps: [],
          },
        ]);
        setTimeout(() => {
          formRef.current.setFieldValue(`entry`, 0);
          formRef.current.setFieldValue(`steps[0].order`, 0);
          formRef.current.setFieldValue(`martingales[0].order`, 0);
        }, 100);
      }

      setTimeout(() => calculateMinimumCapital(), 1000);
    });
  }, []); //eslint-disable-line

  function handleGainGoalChange() {
    const { gain_goal: value } = formRef.current.getData();

    if (value && value > 0) {
      const percent = Number((value * 100) / minimumCapital).toFixed(2);
      setPercentUponGainGoal(percent);
    }
  }

  useEffect(() => {
    handleGainGoalChange();
  }, [minimumCapital]); //eslint-disable-line

  function addNewPlayStep() {
    const newSteps = [
      ...playSteps,
      {
        order: playSteps.length,
        id: Math.random(),
      },
    ];
    setPlaySteps(newSteps);

    newSteps.forEach((step, i) => {
      setTimeout(
        () => formRef.current.setFieldValue(`steps[${i}].order`, step.order),
        100
      );
    });
  }

  function addNewMartingaleStep(index) {
    const martingale = martingales[index];

    martingale.steps = [
      ...martingale.steps,
      {
        order: martingale.steps.length,
      },
    ];

    martingales[index] = martingale;

    const newList = martingales.map((m, i) => (i === index ? martingale : m));

    setMartingales(newList);

    newList.forEach((item) => {
      item.steps.forEach((step, i) => {
        setTimeout(
          () =>
            formRef.current.setFieldValue(
              `martingales[${index}].steps[${i}].order`,
              step.order
            ),
          200
        );
      });
    });
  }

  function addNewMartingale() {
    const newMartingales = [
      ...martingales,
      {
        order: martingales.length,
        steps: [],
      },
    ];
    setMartingales(newMartingales);

    newMartingales.forEach((martingale, i) => {
      setTimeout(
        () =>
          formRef.current.setFieldValue(
            `martingales[${i}].order`,
            martingale.order
          ),
        100
      );
    });
  }

  function reorderSteps() {
    // @TODO implementar um throttle ou debounce
    setTimeout(() => {
      const { steps } = formRef.current.getData();
      const sorted = steps.sort((a, b) => Number(a.order) - Number(b.order));
      sorted.forEach((item, i) => {
        setTimeout(() => {
          formRef.current.setFieldValue(`steps[${i}].path`, item.path || '');
          formRef.current.setFieldValue(`steps[${i}].clicks`, item.clicks);
          formRef.current.setFieldValue(`steps[${i}].order`, item.order);
        }, 100);
      });
    }, 3000);
  }

  async function onSubmit(data) {
    const hasAllRequiredAttrs = (step) =>
      step.hasOwnProperty('clicks') && step.hasOwnProperty('order'); //eslint-disable-line

    const steps = data.steps.filter((step) => hasAllRequiredAttrs(step));

    const finalData = {
      ...data,
      steps: [...steps],
    };

    if (data.martingales && data.martingales.length > 0) {
      let newMartingales = data.martingales.filter((martingale) => martingale.hasOwnProperty('order')); //eslint-disable-line

      newMartingales = newMartingales.map((martingale) => ({
        ...martingale,
        steps: martingale.steps
          ? martingale.steps.filter((step) => hasAllRequiredAttrs(step))
          : [],
      }));

      finalData.martingales = [...newMartingales];
    }

    try {
      await schema.validate(finalData, {
        abortEarly: false,
      });

      const payload = {
        ...finalData,
        best_scenario: data.best_scenario * 100,
        worst_scenario: data.worst_scenario * 100,
        expected_scenario: data.expected_scenario * 100,
        robot_cost: data.robot_cost * 100,
        infra_cost: data.infra_cost * 100,
        percent_upon_profit: data.percent_upon_profit * 100,
        minimum_capital: minimumCapital * 100,
        gain_goal: data.gain_goal * 100,
        recommended_capital: data.recommended_capital * 100,
        validated,
        archived,
        template,
      };

      const { newVersion } = queryString.parse(location.search);

      if (newVersion) {
        await api.post(`admin/strategies/${id}/new-version`, payload);
      } else {
        await api.post('admin/strategies', payload);
      }

      history.push('/strategies');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function handleCheckboxValitedChange() {
    setValidated(!validated);
  }

  function handleCheckboxArchivedChange() {
    setArchived(!archived);
  }

  function handleCheckboxTemplateChange() {
    setTemplate(!template);
  }

  function renderSteps(first, removeStepFunction) {
    return (
      <>
        <Grid item xs={4}>
          <Select label="Path" name="path_id" style={{ width: '100%' }}>
            {paths.map((path) => (
              <MenuItem key={path.id} value={path.id}>
                {path.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Input
            required
            type="number"
            id="clicks"
            name="clicks"
            label="Clicks"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <Input
            required
            type="number"
            id="order"
            name="order"
            label="Ordem"
            fullWidth
            onBlur={reorderSteps}
          />
        </Grid>
        <Grid item xs={2}>
          <Box mt={1} variant>
            <Button
              variant="contained"
              color="secondary"
              onClick={removeStepFunction}
              disabled={first}
            >
              Excluir
            </Button>
          </Box>
        </Grid>
      </>
    );
  }

  function removeStep(step, type, martingale) {
    if (type === 'step') {
      const newSteps = playSteps.filter((playStep) => playStep !== step);
      setPlaySteps(newSteps);
    } else if (type === 'martingale') {
      const oldMartingales = martingales;
      const newSteps = martingale.steps.filter((playStep) => playStep !== step);
      martingale.steps = newSteps;
      const index = oldMartingales.findIndex((m) => m === martingale);
      oldMartingales[index] = martingale;
      setMartingales([...oldMartingales]);
    }
  }

  function removeMartingale(martingale) {
    const newMartingales = martingales.filter((m) => m !== martingale);
    setMartingales([...newMartingales]);
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Form ref={formRef} onSubmit={onSubmit} noValidate>
        <Box mb={5}>
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={validated}
                      onChange={handleCheckboxValitedChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Estratégia validada"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={archived}
                      onChange={handleCheckboxArchivedChange}
                      name="checkedC"
                      color="primary"
                    />
                  }
                  label="Estratégia arquivada"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={template}
                      onChange={handleCheckboxTemplateChange}
                      name="checkedC"
                      color="primary"
                    />
                  }
                  label="Estratégia Modelo"
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Input
                required
                id="name"
                name="name"
                label="Nome da estratégia"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Select label="Risco" name="risk" style={{ width: '100%' }}>
                <MenuItem value="low">Baixo</MenuItem>
                <MenuItem value="medium">Médio</MenuItem>
                <MenuItem value="high">Alto</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="best_scenario"
                name="best_scenario"
                label="Melhor Cenário"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="worst_scenario"
                name="worst_scenario"
                label="Pior Cenário"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="expected_scenario"
                name="expected_scenario"
                label="Cenário Esperado"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="robot_cost"
                name="robot_cost"
                label="Custo do Robô"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="infra_cost"
                name="infra_cost"
                label="Custo de Infraestrutura"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="percent_upon_profit"
                name="percent_upon_profit"
                label="Percentual em cima do lucro"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="roulette_name"
                name="roulette_name"
                label="Nome da roleta"
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <Input
                required
                id="roulette_link"
                name="roulette_link"
                label="Link da Roleta"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="numbers"
                name="numbers"
                label="Números da estratégia"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="numbers_sequence"
                name="numbers_sequence"
                label="Números para estratégia de sequência"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                disabled
                value={minimumCapital}
                label="Capital Mínimo"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="minimum_days"
                name="minimum_days"
                label="Dias mínimos"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                type="number"
                id="entry"
                name="entry"
                label="Entrada"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                type="time"
                id="start_hour"
                name="start_hour"
                label="Hora Início"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                type="time"
                id="end_hour"
                name="end_hour"
                label="Hora Parada"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                thousandSeparator="."
                decimalSeparator=","
                required
                id="gain_goal"
                name="gain_goal"
                label="Meta de ganho diário"
                onChange={handleGainGoalChange}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Box mt={2}>
                <TextField
                  required
                  fullWidth
                  disabled
                  value={percentUponGainGoal}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <CurrencyInput
                thousandSeparator="."
                decimalSeparator=","
                required
                id="recommended_capital"
                name="recommended_capital"
                label="Capital recomendado"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="stripe_product_id"
                name="stripe_product_id"
                label="ID do Produto no Stripe"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="delay"
                defaultValue={200}
                name="delay"
                label="Delay (ms)"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                id="observation"
                multiline
                name="observation"
                label="Parecer da Validação"
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>
        <Box mt={5}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Passos de entrada da estratégia
          </Typography>
          <Paper className={classes.paper}>
            <Box mb={1}>
              <Grid item xs={4}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="base_bet_amount"
                  name="base_bet_amount"
                  label="Valor base de aposta"
                  onChange={calculateMinimumCapital}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Box>
            <Grid container spacing={3}>
              {playSteps.map((step, i) => (
                <Scope key={step.id} path={`steps[${i}]`}>
                  {renderSteps(playSteps.length === 1, () =>
                    removeStep(step, 'step')
                  )}
                </Scope>
              ))}
              <Grid item xs={12}>
                <Button color="primary" onClick={addNewPlayStep}>
                  Adicionar mais um passo
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Box mt={5}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Passos de martingale da estratégia
          </Typography>

          {martingales.map((martingale, i) => (
            <Box key={martingale.order} mt={i !== 0 ? 5 : 0}>
              <Box mb={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => removeMartingale(martingale)}
                >
                  Excluir martingale
                </Button>
              </Box>
              <Paper className={classes.paper}>
                <Scope path={`martingales[${i}]`}>
                  <Box mb={2}>
                    <Grid item xs={4}>
                      <CurrencyInput
                        thousandSeparator="."
                        decimalSeparator=","
                        required
                        name="base_bet_amount"
                        label="Valor base de aposta"
                        onChange={calculateMinimumCapital}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Input
                        required
                        type="number"
                        id="order"
                        name="order"
                        label="Ordem de execução do martingale"
                        fullWidth
                      />
                    </Grid>
                    {martingale.steps.map((step, i2) => (
                      <Scope key={step.id} path={`steps[${i2}]`}>
                        {renderSteps(martingale.steps.length === 1, () =>
                          removeStep(step, 'martingale', martingale)
                        )}
                      </Scope>
                    ))}
                    <Grid item xs={12}>
                      <Button
                        color="primary"
                        onClick={() => addNewMartingaleStep(i)}
                      >
                        Adicionar mais um passo
                      </Button>
                    </Grid>
                  </Grid>
                </Scope>
              </Paper>
            </Box>
          ))}

          <Box mt={2}>
            <Grid item xs={12}>
              <Button color="primary" onClick={addNewMartingale}>
                Adicionar mais um martingale
              </Button>
            </Grid>
          </Box>
        </Box>
        <Button type="submit">Submit</Button>
      </Form>
    </Container>
  );
}

export default StrategyForm;
