import React from 'react';
import { Switch } from 'react-router-dom';
import Filters from '../pages/Filters';
import Paths from '../pages/Paths';
import Report from '../pages/Report';
import Rounds from '../pages/Rounds';
import Strategies from '../pages/Strategies';
import Robots from '../pages/Robots';
import StrategyForm from '../pages/StrategyForm';
import StrategyFormV2 from '../pages/StrategyFormV2';
import StrategyFormV3 from '../pages/StrategyFormV3';
import Route from './Route';
import RobotForm from '../pages/RobotForm';
import Forbidden from '../pages/Forbidden';
import StatisticReport from '../pages/StatisticReport';
import FinancialReport from '../pages/FinancialReport';
import ReportV2 from '../pages/ReportV2';
import StrategyCalculator from '../pages/StrategyCalculator';
import GreyhoundRaces from '../pages/Greygounds/Races';
import ShowGreyhoundRace from '../pages/Greygounds/Show';
import GreyhoundCalculator from '../pages/Greygounds/Calculator';
import BetfairGreyhoundCalculator from '../pages/BetfairGreyhounds/Calculator';
import BetfairGreyhoundRaces from '../pages/BetfairGreyhounds/Races';
import BetfairShowGreyhoundRace from '../pages/BetfairGreyhounds/Show';
import BetfairGreyhoundStratefyForm from '../pages/BetfairGreyhounds/StrategyForm';
import BetfairGreyhoundStrategies from '../pages/BetfairGreyhounds/Strategies';
import BetfairGreyhoundRobotForm from '../pages/BetfairGreyhounds/RobotForm';
import BetfairGreyhoundRobots from '../pages/BetfairGreyhounds/Robots';
import BlazeCrash from '../pages/Blaze/Crash/Results';
import BlazeCrashGraphByOdd from '../pages/Blaze/Crash/GraphByOdd';
import BlazeCrashCalculator from '../pages/Blaze/Crash/Calculator';
import BlazeCrashStrategies from '../pages/Blaze/Crash/Strategies';
import BlazeCrashStrategyForm from '../pages/Blaze/Crash/StrategyForm';
import BlazeCrashRobots from '../pages/Blaze/Crash/Robots';
import BlazeCrashRobotForm from '../pages/Blaze/Crash/RobotForm';
import BlazeCrashReportsEvents from '../pages/Blaze/Crash/Reports/Events';
import CasinoStrategyCalculatorV2 from '../pages/CasinoStrategyCalculatorV2';
import CasinoStrategyCalculatorV3 from '../pages/CasinoStrategyCalculatorV3';
import ReportsStatus from '../pages/ReportsStatus';
import Users from '../pages/Users';
import UserForm from '../pages/UserForm';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact isPrivate master component={Rounds} />
      <Route path="/report" isPrivate master component={Report} />
      <Route path="/reportV2" isPrivate master component={ReportV2} />
      <Route
        path="/statistic-report"
        isPrivate
        master
        exact
        component={StatisticReport}
      />
      <Route path="/financial" isPrivate master component={FinancialReport} />
      <Route path="/paths" isPrivate operator component={Paths} />
      <Route path="/filters" isPrivate master component={Filters} />
      <Route
        path="/strategy/form/:id?"
        operator
        isPrivate
        component={StrategyForm}
        exact
      />
      <Route
        path="/strategy/V2/form/:id?"
        operator
        isPrivate
        exact
        component={StrategyFormV2}
      />
      <Route
        path="/strategy/V3/form/:id?"
        operator
        isPrivate
        exact
        component={StrategyFormV3}
      />
      <Route path="/strategies" isPrivate operator component={Strategies} />
      <Route path="/robots" isPrivate operator component={Robots} />
      <Route path="/robot/form/:id?" operator isPrivate component={RobotForm} />
      <Route
        path="/calculator"
        exact
        operator
        isPrivate
        component={StrategyCalculator}
      />
      <Route
        path="/calculator/v2"
        exact
        operator
        isPrivate
        component={CasinoStrategyCalculatorV2}
      />
      <Route
        path="/calculator/v3"
        exact
        operator
        isPrivate
        component={CasinoStrategyCalculatorV3}
      />
      <Route path="/forbidden" component={Forbidden} />
      <Route
        path="/greyhounds/races"
        exact
        isPrivate
        master
        component={GreyhoundRaces}
      />
      <Route
        path="/greyhounds/races/:external_id"
        exact
        isPrivate
        master
        component={ShowGreyhoundRace}
      />
      <Route
        path="/greyhounds/calculator"
        exact
        isPrivate
        master
        component={GreyhoundCalculator}
      />
      <Route
        path="/betfair/greyhounds/races"
        exact
        isPrivate
        master
        component={BetfairGreyhoundRaces}
      />
      <Route
        path="/betfair/greyhounds/races/:id"
        exact
        isPrivate
        master
        component={BetfairShowGreyhoundRace}
      />
      <Route
        path="/betfair/greyhounds/calculator"
        exact
        isPrivate
        master
        component={BetfairGreyhoundCalculator}
      />
      <Route
        path="/betfair/greyhounds/strategies"
        exact
        isPrivate
        master
        component={BetfairGreyhoundStrategies}
      />
      <Route
        path="/betfair/greyhounds/strategy/form/:id?"
        exact
        isPrivate
        master
        component={BetfairGreyhoundStratefyForm}
      />
      <Route
        path="/betfair/greyhounds/robot/form/:id?"
        exact
        isPrivate
        master
        component={BetfairGreyhoundRobotForm}
      />
      <Route
        path="/betfair/greyhounds/robots"
        exact
        isPrivate
        master
        component={BetfairGreyhoundRobots}
      />
      <Route
        path="/blaze/crash"
        exact
        isPrivate
        master
        component={BlazeCrash}
      />
      <Route
        path="/blaze/crash/graph/odd"
        exact
        isPrivate
        master
        component={BlazeCrashGraphByOdd}
      />
      <Route
        path="/blaze/crash/calculator"
        exact
        isPrivate
        master
        component={BlazeCrashCalculator}
      />
      <Route
        path="/blaze/crash/strategies"
        exact
        isPrivate
        master
        component={BlazeCrashStrategies}
      />
      <Route
        path="/blaze/crash/strategy/form/:id?"
        exact
        isPrivate
        master
        component={BlazeCrashStrategyForm}
      />
      <Route
        path="/blaze/crash/robots"
        exact
        isPrivate
        master
        component={BlazeCrashRobots}
      />
      <Route
        path="/blaze/crash/robot/form/:id?"
        exact
        isPrivate
        master
        component={BlazeCrashRobotForm}
      />
      <Route
        path="/blaze/crash/reports/events"
        exact
        isPrivate
        master
        component={BlazeCrashReportsEvents}
      />
      <Route
        path="/all-reports"
        exact
        isPrivate
        master
        component={ReportsStatus}
      />
      <Route path="/users" exact isPrivate master component={Users} />
      <Route path="/users/form/:id?" operator isPrivate component={UserForm} />
    </Switch>
  );
}
