import React from 'react';

import Chart from 'react-apexcharts';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import api from '../../../../services/api';

const options = {
  chart: {
    zoom: {
      enabled: true,
    },
    id: 'basic-bar',
  },
  legend: {
    position: 'top',
  },
  xaxis: {
    type: 'datetime',
    labels: {
      datetimeUTC: false,
      datetimeFormatter: {
        year: 'yyyy',
      },
    },
  },
};

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function GraphByOdd() {
  const [rows, setRows] = React.useState([]);
  const [start_date, setStartDate] = React.useState('');
  const [end_date, setEndDate] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const fetchData = () => {
    setLoading(true);
    setRows([]);
    api
      .get('blaze/crash', {
        params: {
          count: 10000000000,
          page: 0,
          start_date,
          end_date,
        },
      })
      .then((response) => {
        const formatted = response.data.rows.map((bet) => ({
          x: bet.crash_date,
          y: Number(bet.crash_point) || 1,
        }));
        setRows([
          {
            name: 'Odd',
            data: formatted,
          },
        ]);
        setLoading(false);
      });
  };

  const handleFilter = () => {
    fetchData();
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
        Listagem de jogos
      </Typography>
      <Paper>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={5}>
              <TextField
                id="datetime-local"
                label="Data de início"
                type="datetime-local"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                id="datetime-local"
                label="Data fim"
                type="datetime-local"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={2} justify="center" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleFilter}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Box mt={5}>
        {loading && 'Carregando...'}
        {rows.length > 0 && (
          <Paper>
            <Chart options={options} series={rows} type="line" />
          </Paper>
        )}
      </Box>
    </Container>
  );
}

export default GraphByOdd;
