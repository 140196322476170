import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';

import DateFnsUtils from '@date-io/date-fns';
import App from './App';
import AppProvider from './hooks';

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <AppProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <App />
      </MuiPickersUtilsProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
