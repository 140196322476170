import React, { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TablePagination from '@material-ui/core/TablePagination';

import { format } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  minWidth: 650,
}));

function ShowGreyhoundRace() {
  const classes = useStyles();
  const history = useHistory();
  const [race, setRace] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const fetchRace = () => {
    setLoading(true);
    api.get(`betfair/greyhound/races/${id}`).then((response) => {
      setRace(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchRace();
  }, []);

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        {loading || !race ? (
          <p>Carregando...</p>
        ) : (
          <>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              <br />
              <strong>
                {race.track} -{' '}
                {race.race_date &&
                  format(new Date(race.race_date), "dd/MM/yyyy - HH:mm'h'")}
              </strong>
            </Typography>
            <Box>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colSpan="5"
                        style={{
                          backgroundColor: '#f0f0f0',
                        }}
                      >
                        <strong>Resultado</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {race.runners.map((runner) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={runner.placing}
                      >
                        <TableCell>{runner.placing}°</TableCell>
                        <TableCell>Trap {runner.trap}</TableCell>
                        <TableCell>{runner.name}</TableCell>
                        <TableCell>{runner.odd} odd</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
      </Container>
    </>
  );
}

export default ShowGreyhoundRace;
