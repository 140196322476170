import React, { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  minWidth: 650,
}));

function Report() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);

  const [rows, setRows] = useState([]);
  const [start_date, setStartDate] = React.useState(null);
  const [end_date, setEndDate] = React.useState(null);
  const classes = useStyles();
  const history = useHistory();

  const fetchRaces = (countParam, pageParam) => {
    api
      .get('betfair/greyhound/races', {
        params: {
          count: countParam || rowsPerPage,
          page: pageParam || page,
          start_date,
          end_date,
        },
      })
      .then((response) => {
        setRows(response.data.rows);
        setCount(response.data.count);
      });
  };

  function handleFilter() {
    fetchRaces();
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    history.push({
      search: `page=${0}&count=${+event.target.value}`,
    });
    fetchRaces(+event.target.value, 0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    history.push({
      search: `page=${newPage}&count=${rowsPerPage}`,
    });
    fetchRaces(rowsPerPage, newPage);
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    const pageCount = urlSearchParams.get('count') || rowsPerPage;
    const currentPage = urlSearchParams.get('page') || page;
    setRowsPerPage(Number(pageCount));
    setPage(Number(currentPage));
    fetchRaces(pageCount, currentPage);
  }, []);

  const showRace = (id) => {
    history.push(`races/${id}`);
  };

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          Relatório de Galgos
        </Typography>
        <Paper>
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextField
                  label="Data de início"
                  type="date"
                  className={classes.textField}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Data fim"
                  type="date"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  style={{ marginTop: '12px' }}
                  color="primary"
                  fullWidth
                  onClick={handleFilter}
                >
                  Filtrar Relatório
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Paper>
        <Box mt={5}>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan="5"
                    style={{
                      paddingLeft: '3%',
                      backgroundColor: '#f0f0f0',
                    }}
                  >
                    Virtual
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell align="center">{row.track}</TableCell>
                    <TableCell align="center">
                      {row.started_at &&
                        format(
                          new Date(row.started_at),
                          "dd/MM/yyyy - HH:mm'h'"
                        )}
                    </TableCell>
                    <TableCell align="center">{row.winner}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => showRace(row.id)}
                      >
                        Ver Completo
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 250, 500, 1000]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Registros por página"
          />
        </Box>
      </Container>
    </>
  );
}

export default Report;
