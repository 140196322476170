import React, { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, Button, Snackbar, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';

const getRobotStatus = (robot) => {
  if (robot.robot_status === 'stopped') return 'Parado';
  if (robot.robot_status === 'started') {
    if (robot.robot_secondary_status) {
      if (robot.robot_secondary_status === 'paused_by_time') {
        return 'Horário máximo atingido';
      }
      if (robot.robot_secondary_status === 'reached_gain_goal') {
        return 'Meta diária atingida';
      }
      if (robot.robot_secondary_status === 'reached_monthly_gain_goal') {
        return 'Meta mensal atingida';
      }
      if (robot.robot_secondary_status === 'reached_loss_limit') {
        return 'Limite diário de perda atingido';
      }
      if (robot.robot_secondary_status === 'reached_monthly_loss_limit') {
        return 'Limite mensal de perda atingido';
      }
      if (robot.robot_secondary_status === 'stopped_by_one_lose') {
        return 'Parado! Perdeu 1x hoje';
      }
    } else {
      return 'Rodando';
    }
  }
  if (robot.robot_status === 'waiting_review') return 'Aguardando revisão';
  return '';
};

const columns = [
  { id: 'id', label: '#' },
  {
    id: 'login',
    label: 'Login',
  },
  {
    id: 'host_name',
    label: 'IP Interno',
  },
  {
    id: 'strategy_id',
    label: 'ID Estratégia',
    align: 'center',
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function Paths() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const history = useHistory();

  function fetchRobots() {
    api
      .get('betfair/greyhound/robots')
      .then((response) => setRows(response.data));
  }

  useEffect(() => {
    fetchRobots();
  }, []);

  function handleEdit(id) {
    let url = '/betfair/greyhounds/robot/form';
    if (id) {
      url += `/${id}`;
    }
    history.push(url);
  }

  async function handleStop(robot) {
    if (
      confirm( // eslint-disable-line
        `Deseja realmente ${
          robot.robot_status !== 'started' ? 'ligar' : 'parar'
        } este robô?`
      )
    ) {
      await api.post(`betfair/greyhound/robots/${robot.id}/toggle`);
      setSnackbarOpen(true);
      fetchRobots();
    }
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Operação efetuada com sucesso"
      />
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
        Listagem dos robôs de Corrida de Galgos
      </Typography>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEdit()}
        >
          Criar novo robô
        </Button>
      </Box>
      <Box mt={5}>
        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>{getRobotStatus(row)}</TableCell>
                    <TableCell align="right">
                      <Button
                        style={{ marginLeft: '10px' }}
                        color="primary"
                        variant="outlined"
                        onClick={() => handleEdit(row.id)}
                      >
                        Editar
                      </Button>
                      <Button
                        disabled={
                          row.is_collector && row.robot_status === 'started'
                        }
                        style={{ marginLeft: '10px' }}
                        color={
                          row.robot_status !== 'started'
                            ? 'default'
                            : 'secondary'
                        }
                        variant="outlined"
                        onClick={() => handleStop(row)}
                      >
                        {row.robot_status !== 'started'
                          ? 'Ligar robô'
                          : 'Parar robô'}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Container>
  );
}

export default Paths;
