import React from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';

const columns = [
  { id: 'id', label: 'ID' },
  {
    id: 'name',
    label: 'Nome',
  },
  {
    id: 'email',
    label: 'E-mail',
  },
  {
    id: 'phone',
    label: 'Telefone',
    format: (value) => value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3'),
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function Users() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const classes = useStyles();
  const history = useHistory();

  const fetchRounds = () => {
    api
      .get('users', {
        params: {
          count: rowsPerPage,
          page,
          name,
          email,
          phone,
        },
      })
      .then((response) => {
        setRows(response.data.rows);
        setCount(response.data.count);
      });
  };

  React.useEffect(() => {
    fetchRounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = () => {
    fetchRounds();
  };

  function handleEdit(id) {
    let url = '/users/form';
    if (id) {
      url += `/${id}`;
    }
    history.push(url);
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
        Clientes
      </Typography>
      <Paper>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                label="Nome"
                fullWidth
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                label="Email"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="standard-basic"
                label="Telefone"
                fullWidth
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={2} justify="center" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleFilter}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Box mt={5}>
        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell>Robôs ativos</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>#</TableCell>
                    <TableCell align="right">
                      <Button
                        style={{ marginLeft: '10px' }}
                        color="primary"
                        variant="outlined"
                        onClick={() => handleEdit(row.id)}
                      >
                        Editar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 250, 500, 1000]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Registros por página"
          />
        </Paper>
      </Box>
    </Container>
  );
}

export default Users;
