import { format, parseISO } from 'date-fns';
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Typography,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputAdornment,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import Input from '../../components/Input';
import api from '../../services/api';
import { schema } from './schema';
import {} from '../../utils/jenkins';

import { currencyFormat, toCents } from '../../utils/formatter';

import CurrencyInput from '../../components/CurrencyInput';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function SectionTitle({ title }) {
  return (
    <Grid item xs={12}>
      <h3 style={{ margin: 0 }}>{title}</h3>
    </Grid>
  );
}

let transactionType = 'add';

function UserForm() {
  const formRef = useRef(null);
  const formTransactionRef = useRef(null);
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (id) {
      api.get(`users/${id}`).then((response) => {
        formRef.current.setData(response.data);
        api.get(`transactions/${id}`).then((trResponse) => {
          setTransactions(trResponse.data.slice(0, 10));
        });
      });
    } else {
      alert('O ID do usuário não foi informado');
    }
  }, [id]);

  async function onSubmit(data) {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      await api.put(`users/${id}`, data);

      history.push('/users');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function toggleModal() {
    setOpen(!open);
  }

  async function sendResetPasswordLink() {
    const { email } = formRef.current.getData();
    await api.post('/forgot', {
      email,
    });
    setSnackbarOpen(true);
  }

  async function update() {
    const data = formRef.current.getData();
    await api.put(`users/${id}`, data);
    history.push('/users');
  }

  async function addMoney() {
    toggleModal();
    transactionType = 'add';
  }

  async function removeMoney() {
    toggleModal();
    transactionType = 'remove';
  }

  async function saveTransaction(data, { reset }) {
    try {
      const transactionSchema = Yup.object().shape({
        amount: Yup.string().required('Obrigatório').nullable(),
        description: Yup.string().required('Obrigatório').nullable(),
      });

      await transactionSchema.validate(data, {
        abortEarly: false,
      });

      let amount = toCents(data.amount);
      if (transactionType === 'remove') {
        amount = -Math.abs(amount);
      }

      const payload = {
        user_id: id,
        amount,
        description: data.description,
      };

      await api.post(`transactions`, payload);

      toggleModal();
      reset();

      window.location.reload();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formTransactionRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Operação efetuada com sucesso"
      />
      <Dialog
        open={open}
        onClose={toggleModal}
        aria-labelledby="form-dialog-title"
      >
        <Form ref={formTransactionRef} onSubmit={saveTransaction} noValidate>
          <DialogTitle id="form-dialog-title">
            {transactionType === 'add' ? 'Adicionar saldo' : 'Remover saldo'}
          </DialogTitle>
          <DialogContent>
            <CurrencyInput
              thousandSeparator="."
              decimalSeparator=","
              required
              id="amount"
              name="amount"
              label="Valor"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
            <Input
              style={{ marginTop: '10px' }}
              required
              id="description"
              name="description"
              label="Descrição"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleModal} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => formTransactionRef.current.submitForm()}
              color="primary"
            >
              {transactionType === 'add' ? 'Adicionar' : 'Remover'}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      <Form ref={formRef} onSubmit={onSubmit} noValidate>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <SectionTitle title="Dados pessoais" />
            <Grid item xs={6}>
              <Input
                required
                id="name"
                name="name"
                label="Nome completo"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                required
                id="birth_date"
                name="birth_date"
                label="Data de Nascimento"
                fullWidth
                disabled
              />
            </Grid>
            <SectionTitle title="Dados de contato" />
            <Grid item xs={6}>
              <Input
                required
                id="email"
                name="email"
                label="E-mail"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                required
                id="phone"
                name="phone"
                label="Telefone"
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>

        <Box
          mt={2}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => sendResetPasswordLink()}
          >
            Resetar senha
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => update()}
          >
            Salvar
          </Button>
        </Box>
      </Form>

      <Box>
        <Typography style={{ margin: '30px 0' }} variant="h6" gutterBottom>
          Extrato financeiro
        </Typography>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell align="left">Serviço</TableCell>
                <TableCell align="center">Valor</TableCell>
                <TableCell align="center">Saldo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((dailyOperation) => (
                <tr key={dailyOperation.id}>
                  <TableCell scope="row">
                    {format(
                      parseISO(dailyOperation.cr_at),
                      'dd/MM/yyyy HH:mm:ss'
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {dailyOperation.description}
                  </TableCell>
                  <TableCell align="center">
                    {currencyFormat(dailyOperation.day_amount / 100)}
                  </TableCell>
                  <TableCell align="right">
                    {currencyFormat(dailyOperation.balance / 100)}
                  </TableCell>
                </tr>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => addMoney()}
        >
          Adicionar saldo
        </Button>
        <Button
          style={{ marginLeft: '10px' }}
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => removeMoney()}
        >
          Remover saldo
        </Button>
      </Box>
    </Container>
  );
}

export default UserForm;
