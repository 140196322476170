import {
  Container,
  makeStyles,
  Typography,
  Paper,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Box,
  InputAdornment,
  Chip,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'unform-material-ui';
import { Scope } from '@unform/core';
import * as Yup from 'yup';
import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
} from 'date-fns';
import { parseISO } from 'date-fns/esm';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { prop, sortBy } from 'ramda';
import api from '../../../services/api';
import Input from '../../../components/Input';
import CurrencyInput from '../../../components/CurrencyInput';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  resultContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  resultBox: {
    border: '2px dashed black',
    width: '250px',
    height: '120px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  minWidth: 650,
}));

function GreyhoundCalculator() {
  const classes = useStyles();
  const formRef = useRef(null);
  const [filters, setFilters] = useState([]);
  const [filter, setFilter] = useState(null);
  const [start_date, setStartDate] = React.useState(null);
  const [end_date, setEndDate] = React.useState(null);
  const [entryRows, setEntryRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [stopWhenLosingARound, setStopWhenLosingARound] = useState(false);

  useEffect(() => {
    api.get('admin/filters').then((response) => {
      setFilters(response.data);
    });
  }, []);

  function handleCheckboxStopWhenLosingARoundChange() {
    setStopWhenLosingARound(!stopWhenLosingARound);
  }

  function createEntryRows() {
    const { entry, end } = formRef.current.getData();
    if (entry && end) {
      const arraySize = Number(end) - Number(entry);
      const array = Array.from({ length: arraySize }, (v, k) => k + 1);
      setEntryRows(array);
    } else {
      setEntryRows([]);
    }
  }

  function diffInMonths() {
    return differenceInMonths(parseISO(end_date), parseISO(start_date));
  }

  function differenceInBimesters() {
    return Math.floor(diffInMonths() / 2);
  }

  function differenceInTrimesters() {
    return Math.floor(diffInMonths() / 3);
  }

  function differenceInSemesters() {
    return Math.floor(diffInMonths() / 6);
  }

  function differenceInYears() {
    return Math.floor(diffInMonths() / 12);
  }

  function getGrouppedDates({ group_by }) {
    const result = [];

    const monthDiff = diffInMonths();

    if (group_by === 'month') {
      const array = Array.from({ length: monthDiff + 1 }, (v, k) => k + 1);
      let date = parseISO(start_date);
      array.forEach(() => {
        result.push({
          start_date: startOfMonth(date),
          end_date: endOfMonth(date),
        });
        date = addMonths(date, 1);
      });
    } else if (group_by === 'two_months') {
      const diffInBimesters = differenceInBimesters();
      const array = Array.from(
        { length: diffInBimesters + 1 },
        (v, k) => k + 1
      );
      let date = parseISO(start_date);
      array.forEach(() => {
        result.push({
          start_date: startOfMonth(date),
          end_date: endOfMonth(addMonths(date, 1)),
        });
        date = addMonths(date, 2);
      });
    } else if (group_by === 'three_months') {
      const diffInTrimesters = differenceInTrimesters();
      const array = Array.from(
        { length: diffInTrimesters + 1 },
        (v, k) => k + 1
      );
      let date = parseISO(start_date);
      array.forEach(() => {
        result.push({
          start_date: startOfMonth(date),
          end_date: endOfMonth(addMonths(date, 2)),
        });
        date = addMonths(date, 3);
      });
    } else if (group_by === 'six_months') {
      const diffInSemesters = differenceInSemesters();
      const array = Array.from(
        { length: diffInSemesters + 1 },
        (v, k) => k + 1
      );
      let date = parseISO(start_date);
      array.forEach(() => {
        result.push({
          start_date: startOfMonth(date),
          end_date: endOfMonth(addMonths(date, 5)),
        });
        date = addMonths(date, 6);
      });
    } else if (group_by === 'year') {
      const diffInYears = differenceInYears();
      const array = Array.from({ length: diffInYears + 1 }, (v, k) => k + 1);
      let date = parseISO(start_date);
      array.forEach(() => {
        result.push({
          start_date: startOfMonth(date),
          end_date: endOfMonth(addMonths(date, 11)),
        });
        date = addMonths(date, 12);
      });
    } else {
      result.push({
        start_date,
        end_date,
      });
    }

    result[0].start_date = start_date;
    result[result.length - 1].end_date = end_date;

    return result;
  }

  async function onSubmit(data) {
    if (!start_date || !end_date) {
      // eslint-disable-next-line
      alert('Informe as datas de início e fim');
      return;
    }

    const schema = Yup.object().shape({
      gain_goal: Yup.string().nullable(),
      loss_limit: Yup.string().nullable(),
      monthly_gain_goal: Yup.string().nullable(),
      monthly_loss_limit: Yup.string().nullable(),
      group_by: Yup.string().required('Obrigatório'),
      start_hour: Yup.string(),
      end_hour: Yup.string(),
      greyhound_1: Yup.string().nullable(),
      greyhound_2: Yup.string().nullable(),
      greyhound_3: Yup.string().nullable(),
      greyhound_4: Yup.string().nullable(),
      greyhound_5: Yup.string().nullable(),
      greyhound_6: Yup.string().nullable(),
    });

    // const bet = [
    //   { trap: 1, amount: data.greyhound_1 },
    //   { trap: 2, amount: data.greyhound_1 },
    //   { trap: 3, amount: data.greyhound_3 },
    //   { trap: 4, amount: data.greyhound_4 },
    //   { trap: 5, amount: data.greyhound_5 },
    //   { trap: 6, amount: data.greyhound_6 },
    // ];

    const bet = [];

    if (data.greyhound_1) {
      bet.push({ trap: 1, amount: data.greyhound_1 });
    }
    if (data.greyhound_2) {
      bet.push({ trap: 2, amount: data.greyhound_2 });
    }
    if (data.greyhound_3) {
      bet.push({ trap: 3, amount: data.greyhound_3 });
    }
    if (data.greyhound_4) {
      bet.push({ trap: 4, amount: data.greyhound_4 });
    }
    if (data.greyhound_5) {
      bet.push({ trap: 5, amount: data.greyhound_5 });
    }
    if (data.greyhound_6) {
      bet.push({ trap: 6, amount: data.greyhound_6 });
    }

    try {
      await schema.validate(data, {
        abortEarly: false,
      });
      const grouppedDates = getGrouppedDates({ group_by: data.group_by });
      setLoading(true);
      const result = [];
      await Promise.all(
        grouppedDates.map(async (date) => {
          const payload = {
            ...data,
            start_date: date.start_date,
            end_date: date.end_date,
            bet,
          };
          // eslint-disable-next-line
          const response = await api.post('/greyhound/calculator', payload);
          result.push(response.data);
        })
      );

      console.log(result);
      const sortByDate = sortBy(prop('start_date'));
      setReports(sortByDate(result));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        console.log(validationErrors);
        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }

  function currencyFormat(value) {
    return (value || 0).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
        Calculadora de Estratégias
      </Typography>
      <Form onSubmit={onSubmit} ref={formRef} noValidate>
        <Paper className={classes.paper}>
          <h2>Detalhes do filtro</h2>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Select
                label="Agrupar saldo por"
                name="group_by"
                style={{ width: '100%' }}
                defaultValue="none"
              >
                <MenuItem value="none">Sem agrupamento</MenuItem>
                <MenuItem value="month">Mês</MenuItem>
                <MenuItem value="two_months">Bimestre</MenuItem>
                <MenuItem value="three_months">Trimestre</MenuItem>
                <MenuItem value="six_months">Semestre</MenuItem>
                <MenuItem value="year">Ano</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="datetime-local"
                label="Data de início"
                type="datetime-local"
                fullWidth
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="datetime-local"
                label="Data fim"
                type="datetime-local"
                fullWidth
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => setEndDate(e.target.value)}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <h2>Detalhes da estratégia</h2>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <CurrencyInput
                thousandSeparator="."
                decimalSeparator=","
                required
                id="gain_goal"
                name="gain_goal"
                label="Meta diária"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                thousandSeparator="."
                decimalSeparator=","
                required
                id="loss_limit"
                name="loss_limit"
                label="Limite de perda diária"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                thousandSeparator="."
                decimalSeparator=","
                required
                id="monthly_gain_goal"
                name="monthly_gain_goal"
                label="Meta mensal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                thousandSeparator="."
                decimalSeparator=","
                required
                id="monthly_loss_limit"
                name="monthly_loss_limit"
                label="Limite de perda mensal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                required
                type="time"
                id="start_hour"
                name="start_hour"
                label="Hora de início"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                required
                type="time"
                id="end_hour"
                name="end_hour"
                label="Hora de parada"
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>
        <Box mt={3}>
          <Paper className={classes.paper}>
            <h2>Detalhes financeiros da estratégia</h2>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  name="greyhound_1"
                  label="Galgo 1 - Valor aposta"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  name="greyhound_2"
                  label="Galgo 2 - Valor aposta"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  name="greyhound_3"
                  label="Galgo 3 - Valor aposta"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  name="greyhound_4"
                  label="Galgo 4 - Valor aposta"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  name="greyhound_5"
                  label="Galgo 5 - Valor aposta"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  name="greyhound_6"
                  label="Galgo 6 - Valor aposta"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Button variant="outlined" color="primary" type="submit">
                  Calcular agora
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        {loading ? (
          <Box mt={2}>
            <span>Carregando...</span>
          </Box>
        ) : (
          <div className="section-to-print">
            {reports.map((report) => (
              <>
                <Box mt={2}>
                  <Paper className={classes.paper}>
                    <h2>
                      Resultado do período de{' '}
                      {format(parseISO(report.start_date), 'dd/MM/yyyy')} até{' '}
                      {format(parseISO(report.end_date), 'dd/MM/yyyy')}
                    </h2>
                    {report.rows && report.rows.length > 0 ? (
                      <div className={classes.resultContainer}>
                        <div className={classes.resultBox}>
                          <span>Wins</span>
                          <span>{report.totalWins}</span>
                        </div>
                        <div className={classes.resultBox}>
                          <span>Saldo Final</span>
                          <span>{currencyFormat(report.totalBalance)}</span>
                        </div>
                        <div className={classes.resultBox}>
                          <span>Loss</span>
                          <span>{report.totalLoss}</span>
                        </div>
                      </div>
                    ) : (
                      <h3>O robô de coleta ainda não operava neste período.</h3>
                    )}
                  </Paper>
                </Box>
                <Box mt={2}>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        {report && report.rows && report.rows.length > 0 && (
                          <TableRow>
                            <TableCell align="center">
                              <strong>Data</strong>
                            </TableCell>
                            <TableCell align="center">
                              <strong>Wins</strong>
                            </TableCell>
                            <TableCell align="center">
                              <strong>Loss</strong>
                            </TableCell>
                            <TableCell align="center">
                              <strong>Total do dia</strong>
                            </TableCell>
                            <TableCell align="center">
                              <strong>Saldo</strong>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableHead>
                      <TableBody>
                        {report &&
                          report.rows &&
                          report.rows.map((row) => (
                            <TableRow key={row.date}>
                              <TableCell align="center">{row.date}</TableCell>
                              <TableCell align="center">
                                <strong>{row.result.wins.qtd}</strong> <br />{' '}
                                {currencyFormat(row.result.wins.amount)}
                              </TableCell>
                              <TableCell align="center">
                                <strong>{row.result.loss.qtd}</strong> <br />{' '}
                                {currencyFormat(row.result.loss.amount)}
                              </TableCell>
                              <TableCell align="center">
                                {currencyFormat(row.result.todaysBalance)}
                              </TableCell>
                              <TableCell align="center">
                                <span
                                  style={{
                                    color: row.balance < 0 ? 'red' : 'green',
                                  }}
                                >
                                  {currencyFormat(row.balance)}
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            ))}
          </div>
        )}
      </Form>
    </Container>
  );
}

export default GreyhoundCalculator;
