import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup.string().required('Obrigatório'),
  status: Yup.string().required('Obrigatório'),
  stripe_product_id: Yup.string(),
  membership_fee: Yup.string().required('Obrigatório'),
  fixed_cost: Yup.string().required('Obrigatório'),
  percent_upon_profit: Yup.string().required('Obrigatório'),
  gain_goal: Yup.string().nullable(),
  monthly_gain_goal: Yup.string().nullable(),
  loss_limit: Yup.string().nullable(),
  monthly_loss_limit: Yup.string().nullable(),
  recommended_capital: Yup.string().nullable().required('Obrigatório'),
  minimum_days: Yup.string().required('Obrigatório'),
  start_hour: Yup.string(),
  end_hour: Yup.string(),
  expected_profit: Yup.number()
    .typeError('Deve ser um número válido')
    .min(1, 'Deve ser maior que R$ 1,00')
    .required('Obrigatório'),
  martingale_multiplier_factor: Yup.string().required('Obrigatório'),
  martingale_goes_to: Yup.string().required('Obrigatório'),
});
