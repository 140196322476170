import {
  Box,
  Container,
  Fab,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { Select } from 'unform-material-ui';
import { Save } from '@material-ui/icons';
import { Scope } from '@unform/core';
import Input from '../../../../components/Input';
import api from '../../../../services/api';
import { schema } from './schema';

import CurrencyInput from '../../../../components/CurrencyInput';
import { toCents, toMoney } from '../../../../utils/formatter';
import { formStyles } from '../../../../utils/styles';

function StrategyForm() {
  const formRef = useRef(null);
  const classes = formStyles();

  const [entryRows, setEntryRows] = useState([]);
  const [percentUponGainGoal, setPercentUponGainGoal] = useState(0);
  const [monthlyPercentUponGainGoal, setMonthlyPercentUponGainGoal] = useState(
    0
  );
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  function createEntryRows(entry, goes_to) {
    const isNil = (value) => value === null || value === undefined;

    if (isNil(entry) && isNil(goes_to)) {
      const data = formRef.current.getData();
      entry = data.entry;
      goes_to = data.goes_to;
    }
    if (!isNil(entry) && !isNil(goes_to)) {
      const arraySize = Number(goes_to) - Number(entry);
      const array = Array.from({ length: arraySize }, (v, k) => ({
        id: k + 1,
        filters: [],
      }));
      setEntryRows(array);
    } else {
      setEntryRows([]);
    }
  }

  useEffect(() => {
    if (id) {
      api.get(`blaze/crash/strategies/${id}`).then((strategyResponse) => { // eslint-disable-line
        const strategy = {
          ...strategyResponse.data,
          membership_fee: strategyResponse.data.membership_fee / 100,
          fixed_cost: strategyResponse.data.fixed_cost / 100,
          minimum_capital: strategyResponse.data.minimum_capital / 100,
          percent_upon_profit: strategyResponse.data.percent_upon_profit / 100,
          gain_goal: strategyResponse.data.gain_goal / 100,
          monthly_gain_goal: strategyResponse.data.monthly_gain_goal / 100,
          loss_limit: strategyResponse.data.loss_limit / 100,
          monthly_loss_limit: strategyResponse.data.monthly_loss_limit / 100,
          recommended_capital: strategyResponse.data.recommended_capital / 100,
          entry_1: Number(strategyResponse.data.entry || 0) + 1,
          sequence_strategy_odd: toMoney(
            strategyResponse.data.sequence_strategy_odd
          ),
        };

        strategy.martingales = strategy.martingales.map((m) => ({
          amount: toMoney(m.amount),
          odd: toMoney(m.odd),
        }));

        createEntryRows(
          strategyResponse.data.entry,
          strategyResponse.data.goes_to
        );

        setTimeout(() => formRef.current.setData(strategy), 0);
      });
    }
  }, []); //eslint-disable-line

  function handleGainGoalChange() {
    const { gain_goal, minimum_capital } = formRef.current.getData();

    if (gain_goal && gain_goal > 0 && minimum_capital && minimum_capital > 0) {
      const percent = Number((gain_goal * 100) / minimum_capital).toFixed(2);
      setPercentUponGainGoal(percent);
    }
  }

  function handleMonthlyGainGoalChange() {
    const { monthly_gain_goal, minimum_capital } = formRef.current.getData();

    if (
      monthly_gain_goal &&
      monthly_gain_goal > 0 &&
      minimum_capital &&
      minimum_capital > 0
    ) {
      const percent = Number(
        (monthly_gain_goal * 100) / minimum_capital
      ).toFixed(2);
      setMonthlyPercentUponGainGoal(percent);
    }
  }

  async function onSubmit(data) {
    data.martingales = data.martingales.map((m, i) => ({
      ...m,
      order: i,
    }));

    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      const payload = {
        ...data,
        membership_fee: data.membership_fee * 100,
        fixed_cost: data.fixed_cost * 100,
        percent_upon_profit: data.percent_upon_profit * 100,
        minimum_capital: data.minimum_capital * 100,
        gain_goal: data.gain_goal * 100,
        monthly_gain_goal: data.monthly_gain_goal * 100,
        loss_limit: data.loss_limit * 100,
        monthly_loss_limit: data.monthly_loss_limit * 100,
        recommended_capital: data.recommended_capital * 100,
        sequence_strategy_odd: toCents(data.sequence_strategy_odd),
      };

      payload.martingales = payload.martingales.map((m) => ({
        ...m,
        amount: toCents(m.amount),
        odd: toCents(m.odd),
      }));

      const { newVersion } = queryString.parse(location.search);

      if (newVersion) {
        await api.post(`blaze/crash/strategies/${id}/new-version`, payload);
      } else {
        await api.post('blaze/crash/strategies', payload);
      }

      history.push('/blaze/crash/strategies');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function isDuplicate() {
    const qs = queryString.parse(location.search);
    return qs.duplicate;
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      {id && (
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {isDuplicate() ? `Cópia da Estratégia ${id}` : `Estratégia ${id}`}
        </Typography>
      )}

      <Form ref={formRef} onSubmit={onSubmit} noValidate>
        <Box>
          <Paper className={classes.paper}>
            <h3>Dados da Estratégia</h3>

            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Input
                  required
                  id="name"
                  name="name"
                  label="Nome da estratégia"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Select
                  name="status"
                  label="Situação da estratégia"
                  fullWidth
                  defaultValue="in_operation"
                  style={{ width: '100%' }}
                >
                  <MenuItem value="in_operation">Em operação</MenuItem>
                  <MenuItem value="validated">Validada</MenuItem>
                  <MenuItem value="archived">Arquivada</MenuItem>
                  <MenuItem value="template">Estratégia Modelo</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <h3 style={{ marginTop: '28px' }}>
              Dados de Cobrança da estratégia
            </h3>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Input
                  required
                  id="stripe_product_id"
                  name="stripe_product_id"
                  label="ID Produto Stripe"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  required
                  id="membership_fee"
                  name="membership_fee"
                  label="Custo adesão"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  required
                  id="fixed_cost"
                  name="fixed_cost"
                  label="Custo fixo"
                  defaultValue={100}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  required
                  id="percent_upon_profit"
                  name="percent_upon_profit"
                  label="Custo lucro percentual"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <h3 style={{ marginTop: '28px' }}>
              Dados financeiros da estratégia
            </h3>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="gain_goal"
                  defaultValue={0}
                  name="gain_goal"
                  label="Meta de ganho diário"
                  onChange={handleGainGoalChange}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Box mt={2}>
                  <TextField
                    required
                    fullWidth
                    disabled
                    value={percentUponGainGoal}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="monthly_gain_goal"
                  defaultValue={0}
                  name="monthly_gain_goal"
                  label="Meta de ganho mensal"
                  onChange={handleMonthlyGainGoalChange}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Box mt={2}>
                  <TextField
                    required
                    fullWidth
                    disabled
                    value={monthlyPercentUponGainGoal}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="loss_limit"
                  defaultValue={0}
                  name="loss_limit"
                  label="Limite de perda diário"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="monthly_loss_limit"
                  defaultValue={0}
                  name="monthly_loss_limit"
                  label="Limite de perda mensal"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="minimum_capital"
                  name="minimum_capital"
                  label="Capital mínimo"
                  fullWidth
                  onChange={() => {
                    handleGainGoalChange();
                    handleMonthlyGainGoalChange();
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="recommended_capital"
                  name="recommended_capital"
                  label="Capital recomendado"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <h3>Dados operacionais da estratégia</h3>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Input
                  required
                  id="minimum_days"
                  name="minimum_days"
                  label="Dias mínimos"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  required
                  type="time"
                  id="start_hour"
                  name="start_hour"
                  label="Hora de início"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  required
                  type="time"
                  id="end_hour"
                  name="end_hour"
                  label="Hora de parada"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Box mt={3}>
          <Paper className={classes.paper}>
            <h3>Passos da aposta inicial da estratégia</h3>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Input
                  required
                  name="sequence_strategy_odd"
                  label="Odd estratégia de sequências"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  required
                  id="entry"
                  name="entry"
                  label="Entrada"
                  onChange={(e) => {
                    const result = Number(e.target.value) + 1;
                    formRef.current.setFieldValue('entry_1', result);
                    createEntryRows();
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  required
                  disabled
                  id="entry"
                  name="entry_1"
                  label="Entra no"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  required
                  id="goes_to"
                  name="goes_to"
                  label="Vai até"
                  fullWidth
                  onChange={() => createEntryRows()}
                />
              </Grid>
              {entryRows.map((row, i) => (
                <Scope key={row} path={`martingales[${i}]`}>
                  <Grid item xs={6}>
                    <Input
                      required
                      name="odd"
                      label={
                        i === 0 ? 'Entrada base (ODD)' : `Martingale ${i} (ODD)`
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CurrencyInput
                      thousandSeparator="."
                      decimalSeparator=","
                      required
                      name="amount"
                      label={
                        i === 0
                          ? 'Entrada base (Valor de aposta)'
                          : `Martingale ${i} (Valor de aposta)`
                      }
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Scope>
              ))}
            </Grid>
          </Paper>
        </Box>
        <Fab
          onClick={() => formRef.current.submitForm()}
          color="primary"
          aria-label="add"
          variant="extended"
          className={classes.fab}
        >
          <Save /> &nbsp; Salvar Estratégia
        </Fab>
      </Form>
    </Container>
  );
}

export default StrategyForm;
