import { makeStyles } from '@material-ui/core';

const defaultStyles = (theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
});

const formStyles = makeStyles((theme) => ({
  ...defaultStyles(theme),
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(5),
    display: 'flex',
  },
}));

const calculatorResultStyles = makeStyles((theme) => ({
  ...defaultStyles(theme),
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  resultContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  resultBox: {
    border: '2px dashed black',
    width: '250px',
    height: '120px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  minWidth: 650,
}));

export { formStyles, calculatorResultStyles };
