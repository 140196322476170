import React, { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from '../../services/api';

const columns = [
  { id: 'id', label: '#' },
  {
    id: 'name',
    label: 'Nome',
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function Filters() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [numbers, setNumbers] = useState(null);

  function searchFilters() {
    api.get('admin/filters').then((response) => setRows(response.data));
  }

  useEffect(() => {
    searchFilters();
  }, []);

  function toggleModal() {
    setOpen(!open);
  }

  async function handleSave() {
    if (!name || !numbers) {
      // eslint-disable-next-line
      alert('Todos os campos são obrigatórios');
      return;
    }

    if (id) {
      await api.put(`admin/filters/${id}`, {
        name,
        numbers,
      });
    } else {
      await api.post('admin/filters', {
        name,
        numbers,
      });
    }

    setId(null);
    setName(null);
    setNumbers(null);

    toggleModal();
    searchFilters();
    setSnackbarOpen(true);
  }

  function createNewfilter() {
    setId(null);
    setName(null);
    setNumbers(null);
    toggleModal();
  }

  function handleCellClick(data) {
    setId(data.id);
    setName(data.name);
    setNumbers(data.numbers);
    toggleModal();
  }

  async function handleRemove(identifier) {
    if (confirm('Deseja realmente remover esse filtro de relatório?')) { // eslint-disable-line
      await api.delete(`admin/filters/${identifier}`);
      searchFilters();
    }
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Operação efetuada com sucesso"
      />
      <Dialog
        open={open}
        onClose={toggleModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Filtro de relatório</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Informe os dados do Filtro de Relatório
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome do filtro"
            type="text"
            fullWidth
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            multiline
            margin="dense"
            id="numbers"
            label="Números"
            type="text"
            fullWidth
            defaultValue={numbers}
            onChange={(e) => setNumbers(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
        Listagem de filtros
      </Typography>
      <Box>
        <Button variant="contained" color="primary" onClick={createNewfilter}>
          Criar novo Filtro
        </Button>
      </Box>
      <Box mt={5}>
        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          onClick={() => handleCellClick(row)}
                        >
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell align="right">
                      <Button
                        color="secondary"
                        variant="outlined"
                        outlin
                        onClick={() => handleRemove(row.id)}
                      >
                        Remover
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Container>
  );
}

export default Filters;
