import React from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import api from '../../services/api';

const columns = [
  { id: 'id', label: '#' },
  {
    id: 'createdAt',
    label: 'Data',
    format: (value) => format(parseISO(value), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    id: 'roulette_name',
    label: 'Roleta',
  },
  {
    id: 'number',
    label: 'Número',
  },
  {
    id: 'parity',
    label: 'Paridade',
  },
  {
    id: 'color',
    label: 'Cor',
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const getColor = (number) => {
  const redNumbers = [
    1,
    3,
    5,
    7,
    9,
    12,
    14,
    16,
    18,
    19,
    21,
    23,
    27,
    25,
    30,
    32,
    36,
    34,
  ];
  let color;
  if (redNumbers.includes(number)) {
    color = 'Vermelho';
  } else if (number === 0) {
    color = 'Verde';
  } else {
    color = 'Preto';
  }
  return color;
};

const getParity = (number) => {
  // eslint-disable-next-line
  const isOdd = number & 1;
  const isEven = !isOdd;
  const isGreen = number === 0;
  let parity;
  if (isGreen) {
    parity = '-';
  } else if (isOdd) {
    parity = 'Ímpar';
  } else if (isEven) {
    parity = 'Par';
  }
  return parity;
};

function Rounds() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [roulette_name, setRouletteName] = React.useState('');
  const [start_date, setStartDate] = React.useState('');
  const [end_date, setEndDate] = React.useState('');
  const classes = useStyles();

  const fetchRounds = () => {
    api
      .get('rounds', {
        params: {
          count: rowsPerPage,
          page,
          roulette_name,
          start_date,
          end_date,
        },
      })
      .then((response) => {
        const data = response.data.rows.map((item) => ({
          ...item,
          color: getColor(item.number),
          parity: getParity(item.number),
        }));
        setRows(data);
        setCount(response.data.count);
      });
  };

  React.useEffect(() => {
    fetchRounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = () => {
    fetchRounds();
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
        Listagem de rodadas
      </Typography>
      <Paper>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <TextField
                id="standard-basic"
                label="Nome da roleta"
                fullWidth
                onChange={(e) => setRouletteName(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="datetime-local"
                label="Data de início"
                type="datetime-local"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="datetime-local"
                label="Data fim"
                type="datetime-local"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={2} justify="center" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleFilter}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Box mt={5}>
        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 250, 500, 1000]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Registros por página"
          />
        </Paper>
      </Box>
    </Container>
  );
}

export default Rounds;
