import React, { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { format, parse } from 'date-fns';
import { sort, uniq } from 'ramda';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  minWidth: 650,
}));

function FinancialReport() {
  const [report, setReport] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [dates, setDates] = React.useState([]);
  const [currentMonthBalance, setCurrentMonthBalance] = React.useState(null);
  const [todayBalance, setTodayBalance] = React.useState(null);

  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    api.get('history/financial').then((response) => {
      setReport(response.data.rows);
      setCurrentMonthBalance(response.data.currentMonthBalance);
      setTodayBalance(response.data.todayBalance);

      response.data.rows.forEach((item) => {
        Object.keys(item.balance_days).forEach((key) => {
          const date = parse(key, 'ddd/MM/yyyy', new Date());
          dates.push(date);
        });
      });

      setLoading(false);

      const diffDates = (a, b) => a - b;

      const uniqueDates = uniq(dates);

      setDates(sort(diffDates, uniqueDates));

      setLoading(false);
    });
  }, []);

  function formatToReal(value) {
    return ((value || 0) / 100).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  function getTotalBalanceDay(key) {
    const formattedKey = format(key, 'dd/MM/yyyy');
    const sum = report
      .map((r) => r.balance_days[formattedKey] || 0)
      .reduce((a, b) => a + b, 0);

    return sum;
  }

  function getAllSum() {
    return report.map((r) => r.total_balance).reduce((a, b) => a + b, 0);
  }

  function getDayBalancePercentage(key, row) {
    const formattedKey = format(key, 'dd/MM/yyyy');
    const amount = row.balance_days[formattedKey];
    if (!amount || amount === 0) return 0;

    const addZeroes = (number) =>
      Number.isInteger(number) ? number : number.toFixed(2);

    const result = (amount * 100) / row.strategy_minimum_capital;

    return addZeroes(result);
  }

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          Relatório de Desempenho
        </Typography>
        {todayBalance && currentMonthBalance && (
          <Box mb={2}>
            <Grid container>
              <Grid item xs={6}>
                <TableContainer component={Paper}>
                  <Table
                    style={{ display: 'flex', justifyContent: 'center' }}
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <strong>LUCRO OU PREJUÍZO NO DIA</strong>
                          </TableCell>
                          <TableCell>
                            {formatToReal(todayBalance.amount)}
                          </TableCell>
                          <TableCell>{todayBalance.percent}%</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>LUCRO OU PREJUÍZO NO MÊS</strong>
                          </TableCell>
                          <TableCell>
                            {formatToReal(currentMonthBalance.amount)}
                          </TableCell>
                          <TableCell>{currentMonthBalance.percent}%</TableCell>
                        </TableRow>
                      </TableBody>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box>
          {loading ? (
            <p>Carregando...</p>
          ) : (
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  {report.length > 0 && (
                    <TableRow>
                      <TableCell align="center">
                        <strong>ITEM</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>APOSTADOR</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>ESTRATÉGIA</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>NOME DA ESTRATÉGIA</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>ENTRA NO</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>VAI ATÉ</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>DIAS DE ATIVIDADE</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>CAPITAL MÍNIMO</strong>
                      </TableCell>
                      {dates.map((date, i) => (
                        <TableCell
                          align="center"
                          key={`${format(date, 'dd/MM/yyyy')}${Math.random()}`}
                        >
                          <strong>{format(date, 'dd/MM/yyyy')}</strong>
                        </TableCell>
                      ))}
                      <TableCell align="center">
                        <strong>LUCRO OU PREJUÍZO</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>%</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>MÉDIA DOS dias de atividade</strong>
                      </TableCell>
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  <>
                    {report.map((row, i) => (
                      <>
                        <TableRow key={row.strategy_id}>
                          <TableCell align="center">{i + 1}</TableCell>
                          <TableCell align="center">{row.robot_name}</TableCell>
                          <TableCell align="center">
                            {row.strategy_id}
                          </TableCell>
                          <TableCell align="center">
                            {row.strategy_name}
                          </TableCell>
                          <TableCell align="center">
                            {`${row.strategy_entry + 1}º` || 0}
                          </TableCell>
                          <TableCell align="center">
                            {`${
                              row.strategy_martingale_length +
                              row.strategy_entry +
                              1
                            }º`}
                          </TableCell>
                          <TableCell align="center">
                            {row.activity_days}
                          </TableCell>
                          <TableCell align="center">
                            {formatToReal(row.strategy_minimum_capital)}
                          </TableCell>
                          {dates.map((key) => (
                            <TableCell
                              align="center"
                              key={`${key}${Math.random()}`}
                            >
                              {formatToReal(
                                row.balance_days[format(key, 'dd/MM/yyyy')]
                              )}
                            </TableCell>
                          ))}
                          <TableCell align="center">
                            {formatToReal(row.total_balance)}
                          </TableCell>
                          <TableCell align="center">{`${row.percent}%`}</TableCell>
                          <TableCell align="center">{`${row.average_days_of_activity}%`}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          {dates.map((key) => (
                            <TableCell
                              style={{ backgroundColor: '#84c284' }}
                              align="center"
                              key={`${key}${Math.random()}`}
                            >
                              {getDayBalancePercentage(key, row)}%
                            </TableCell>
                          ))}
                          <TableCell />
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      </>
                    ))}
                    <TableRow style={{ backgroundColor: '#cbd1cb' }}>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell>
                        <strong>TOTAIS</strong>
                      </TableCell>
                      {dates.map((key) => (
                        <TableCell
                          align="center"
                          key={`${key}${Math.random()}`}
                        >
                          {formatToReal(getTotalBalanceDay(key))}
                        </TableCell>
                      ))}
                      <TableCell align="center">
                        {formatToReal(getAllSum())}
                      </TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Container>
    </>
  );
}

export default FinancialReport;
