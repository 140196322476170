import {
  Box,
  Button,
  Container,
  Fab,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { Select } from 'unform-material-ui';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Save } from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { filter } from 'ramda';
import Input from '../../components/Input';
import api from '../../services/api';
import { schema } from './schema';

import CurrencyInput from '../../components/CurrencyInput';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(5),
  },
}));

function StrategyFormV2() {
  const formRef = useRef(null);
  const classes = useStyles();
  const [playSteps, setPlaySteps] = useState([]);
  const [martingales, setMartingales] = useState([]);
  const [validated, setValidated] = useState(false);
  const [archived, setArchived] = useState(false);
  const [template, setTemplate] = useState(false);
  const [percentUponGainGoal, setPercentUponGainGoal] = useState(0);
  const [monthlyPercentUponGainGoal, setMonthlyPercentUponGainGoal] = useState(
    0
  );
  const [minimumCapital, setMinimumCapital] = useState(0);
  const [entryOn, setEntryOn] = useState(null);
  const [goTo, setGoTo] = useState(null);
  const [paths, setPaths] = useState([]); //eslint-disable-line
  const [status, setStatus] = useState('em_operacao');
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const [baseBetTotalClicks, setBaseBetTotalClicks] = useState(0);
  const [baseBetExecutionTime, setBaseBetExecutionTime] = useState(0);

  const [martingalesBetTotalClicks, setMartingalesBetTotalClicks] = useState(
    []
  );
  const [martingalesExecutionTime, setMartingalesExecutionTime] = useState([]);
  const [stopWhenLosingARound, setStopWhenLosingARound] = useState(false);
  const [filters, setFilters] = useState([]);
  const [numbers, setNumbers] = useState(null);
  const [numbersInSequence, setNumbersInSequence] = useState(null);

  function calculateBaseBetTotalClicks() {
    if (formRef && formRef.current) {
      const { steps, delay } = formRef.current.getData();
      if (steps) {
        const sum = steps
          .map((step) => Number(step.clicks))
          .reduce((a, b) => a + b, 0);
        setBaseBetTotalClicks(sum);

        const executionTimeInMs = (sum * (delay || 0)) / 1000;
        setBaseBetExecutionTime(`${executionTimeInMs}s`);
      }
    }
  }

  function calculateMartingalesTotalClicks(martingalesParam) {
    const clicks = [];
    const times = [];

    if (formRef && formRef.current) {
      const data = formRef.current.getData();
      const martingaleList = martingalesParam || data.martingales;
      if (martingaleList && martingaleList.length > 0) {
        martingaleList.forEach((martingale) => {
          if (martingale.steps && martingale.steps.length > 0) {
            const sum = martingale.steps
              .map((step) => (step.clicks ? Number(step.clicks) : 0))
              .reduce((a, b) => a + b, 0);
            clicks.push(sum);
            const executionTimeInMs = (sum * (data.delay || 0)) / 1000;
            times.push(`${executionTimeInMs}s`);
          }
        });
      }
    }

    setMartingalesBetTotalClicks(clicks);
    setMartingalesExecutionTime(times);
  }

  function calculateMinimumCapital() {
    if (formRef.current) {
      const {
        base_bet_amount,
        martingales: currentMartingales,
      } = formRef.current.getData();

      let amountMartingalesBet = 0;
      let amountBase = 0;

      if (base_bet_amount) {
        amountBase = base_bet_amount;
      }

      if (currentMartingales && currentMartingales.length > 0) {
        amountMartingalesBet = currentMartingales
          .map((m) => m.base_bet_amount || 0)
          .reduce((a, b) => a + b, 0);
      }

      return setMinimumCapital(amountBase + amountMartingalesBet);
    }
    return 0;
  }

  useEffect(() => {
    api.get('admin/paths').then((response) => {
      setPaths(response.data);

      api.get('admin/filters').then((filtersResponse) => {
        setFilters(filtersResponse.data);
        if (id) {
          api.get(`admin/strategies/${id}`).then((strategyResponse) => { // eslint-disable-line
            const strategy = {
              ...strategyResponse.data,
              best_scenario: strategyResponse.data.best_scenario / 100,
              worst_scenario: strategyResponse.data.worst_scenario / 100,
              expected_scenario: strategyResponse.data.expected_scenario / 100,
              robot_cost: strategyResponse.data.robot_cost / 100,
              infra_cost: strategyResponse.data.infra_cost / 100,
              minimum_capital: strategyResponse.data.minimum_capital / 100,
              percent_upon_profit:
                strategyResponse.data.percent_upon_profit / 100,
              gain_goal: strategyResponse.data.gain_goal / 100,
              monthly_gain_goal: strategyResponse.data.monthly_gain_goal / 100,
              loss_limit: strategyResponse.data.loss_limit / 100,
              monthly_loss_limit:
                strategyResponse.data.monthly_loss_limit / 100,
              recommended_capital:
                strategyResponse.data.recommended_capital / 100,
              delay: strategyResponse.data.delay || 0,
              last_year_income: strategyResponse.data.last_year_income / 100,
              last_year_assertiveness_rate:
                strategyResponse.data.last_year_assertiveness_rate / 100,
            };

            const foundNumbers = filtersResponse.data.find(
              (f) => f.numbers === strategy.numbers
            );

            if (!foundNumbers) {
              // eslint-disable-next-line
              alert(
                `Nenhum filtro encontrado para os números da estratégia. Crie o filtro e depois retorne aqui. ${strategy.numbers}`
              );
              history.push('/filters');
              return;
            }

            setNumbers(foundNumbers);

            const foundNumbersInSequence = filtersResponse.data.find(
              (f) => f.numbers === strategy.numbers_sequence
            );

            if (!foundNumbersInSequence) {
              // eslint-disable-next-line
              alert(
                `Nenhum filtro encontrado para os números de sequência da estratégia. Crie o filtro e depois retorne aqui. ${strategy.numbers_sequence}`
              );
              history.push('/filters');
              return;
            }

            setNumbersInSequence(foundNumbersInSequence);

            const sortedMartingales = strategyResponse.data.martingales.sort(
              (a, b) => a.order - b.order
            );

            const sortedMartingalesWithSortedSteps = sortedMartingales.map(
              (martingale) => ({
                ...martingale,
                steps: martingale.steps.sort((a, b) => a.order - b.order),
              })
            );

            const steps = strategyResponse.data.steps.sort(
              (a, b) => a.order - b.order
            );

            setEntryOn(strategyResponse.data.entry + 1);

            setPlaySteps(steps);

            if (
              strategyResponse.data.martingales.length > 0 &&
              strategyResponse.data.entry !== null
            ) {
              setGoTo(
                strategyResponse.data.martingales.length +
                  Number(strategyResponse.data.entry) +
                  1
              );
            }

            setMartingales(sortedMartingalesWithSortedSteps);

            formRef.current.setData(strategy);

            calculateBaseBetTotalClicks();
            calculateMartingalesTotalClicks(sortedMartingalesWithSortedSteps);

            // setArchived(strategyResponse.data.archived);

            setStopWhenLosingARound(
              strategyResponse.data.stop_when_losing_a_round
            );

            const { duplicate } = queryString.parse(location.search);

            if (duplicate) {
              setStatus('em_operacao');
            } else if (strategyResponse.data.validated) {
              setStatus('validada');
            } else if (strategyResponse.data.archived) {
              setStatus('arquivada');
            } else if (strategyResponse.data.template) {
              setStatus('modelo');
            } else {
              setStatus('em_operacao');
            }
          });
        } else {
          setPlaySteps([
            {
              order: 0,
              id: (Math.random() * 10000000).toFixed(0),
            },
          ]);
          setMartingales([
            {
              order: 0,
              steps: [],
            },
          ]);
          setTimeout(() => {
            formRef.current.setFieldValue(`entry`, 0);
            formRef.current.setFieldValue(`steps[0].order`, 0);
            formRef.current.setFieldValue(`martingales[0].order`, 0);
          }, 100);
        }

        setTimeout(() => calculateMinimumCapital(), 1000);
      });
    });
  }, []); //eslint-disable-line

  useEffect(() => {
    const { entry } = formRef.current.getData();
    if (martingales.length > 0 && entry) {
      setGoTo(martingales.length + Number(entry) + 1);
    }
  }, [martingales]);

  function handleGainGoalChange() {
    const { gain_goal: value } = formRef.current.getData();

    if (value && value > 0) {
      const percent = Number((value * 100) / minimumCapital).toFixed(2);
      setPercentUponGainGoal(percent);
    }
  }

  function handleMonthlyGainGoalChange() {
    const { monthly_gain_goal: value } = formRef.current.getData();

    if (value && value > 0) {
      const percent = Number((value * 100) / minimumCapital).toFixed(2);
      setMonthlyPercentUponGainGoal(percent);
    }
  }

  useEffect(() => {
    handleGainGoalChange();
    handleMonthlyGainGoalChange();
  }, [minimumCapital]); //eslint-disable-line

  function addNewPlayStep() {
    const newSteps = [
      ...playSteps,
      {
        order: playSteps.length,
        id: Math.random(),
      },
    ];
    setPlaySteps(newSteps);

    newSteps.forEach((step, i) => {
      setTimeout(
        () => formRef.current.setFieldValue(`steps[${i}].order`, step.order),
        100
      );
    });

    calculateBaseBetTotalClicks();
  }

  function addNewMartingaleStep(index) {
    const martingale = martingales[index];

    martingale.steps = [
      ...martingale.steps,
      {
        order: martingale.steps.length,
        id: new Date().getTime(),
      },
    ];

    martingales[index] = martingale;

    const newList = martingales.map((m, i) => (i === index ? martingale : m));

    setMartingales(newList);

    newList.forEach((item) => {
      item.steps.forEach((step, i) => {
        setTimeout(
          () =>
            formRef.current.setFieldValue(
              `martingales[${index}].steps[${i}].order`,
              step.order
            ),
          200
        );
      });
    });

    calculateMartingalesTotalClicks();
  }

  function addNewMartingale() {
    const newMartingales = [
      ...martingales,
      {
        order: martingales.length,
        steps: [],
      },
    ];
    setMartingales(newMartingales);

    newMartingales.forEach((martingale, i) => {
      setTimeout(
        () =>
          formRef.current.setFieldValue(
            `martingales[${i}].order`,
            martingale.order
          ),
        100
      );
    });
  }

  async function onSubmit(data) {
    calculateMinimumCapital();

    const hasAllRequiredAttrs = (step) => step.hasOwnProperty('clicks'); //eslint-disable-line

    data.steps = data.steps.map((step, index) => {
      const { path_id, path } = playSteps[index];

      return {
        ...step,
        path_id,
        path,
      };
    });

    const steps = data.steps
      .filter((step) => hasAllRequiredAttrs(step))
      .map((step, index) => ({
        ...step,
        order: index,
      }));

    const finalData = {
      ...data,
      steps: [...steps],
    };

    if (data.martingales && data.martingales.length > 0) {
      let newMartingales = data.martingales.filter((martingale) => martingale.hasOwnProperty('order')); //eslint-disable-line

      newMartingales = newMartingales.map((martingale, martingaleIndex) => {
        martingale.steps = martingale.steps.map((step, stepIndex) => {
          const m = martingales[martingaleIndex];
          const { path_id, path } = m.steps[stepIndex];

          return {
            ...step,
            path_id,
            path,
          };
        });

        return {
          ...martingale,
          steps: martingale.steps
            ? martingale.steps
                .filter((step) => hasAllRequiredAttrs(step))
                .map((step, index) => ({
                  ...step,
                  order: index,
                }))
            : [],
        };
      });

      finalData.martingales = [...newMartingales];
    }

    try {
      await schema.validate(finalData, {
        abortEarly: false,
      });

      const payload = {
        ...finalData,
        best_scenario: data.best_scenario * 100,
        worst_scenario: data.worst_scenario * 100,
        expected_scenario: data.expected_scenario * 100,
        robot_cost: data.robot_cost * 100,
        infra_cost: data.infra_cost * 100,
        percent_upon_profit: data.percent_upon_profit * 100,
        minimum_capital: minimumCapital * 100,
        gain_goal: data.gain_goal * 100,
        monthly_gain_goal: data.monthly_gain_goal * 100,
        loss_limit: data.loss_limit * 100,
        monthly_loss_limit: data.monthly_loss_limit * 100,
        recommended_capital: data.recommended_capital * 100,
        validated,
        archived,
        template,
        stop_when_losing_a_round: stopWhenLosingARound,
        numbers: numbers.numbers,
        numbers_sequence: numbersInSequence.numbers,
        last_year_income: data.last_year_income * 100,
        last_year_assertiveness_rate: data.last_year_assertiveness_rate * 100,
      };

      const { newVersion } = queryString.parse(location.search);

      if (newVersion) {
        await api.post(`admin/strategies/${id}/new-version`, payload);
      } else {
        await api.post('admin/strategies', payload);
      }

      history.push('/strategies');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function handleStatusChange(event) {
    const { value } = event.target;
    setValidated(false);
    setArchived(false);
    setTemplate(false);

    if (value === 'validada') {
      setValidated(true);
    } else if (value === 'arquivada') {
      setArchived(true);
    } else if (value === 'modelo') {
      setTemplate(true);
    }

    setStatus(value);
  }

  function isDuplicate() {
    const qs = queryString.parse(location.search);
    return qs.duplicate;
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
      background: '#FFF',
    }),
  });

  function renderSteps(
    first,
    removeStepFunction,
    type,
    ref,
    draggableProps,
    dragHandleProps,
    provided,
    snapshot,
    step
  ) {
    return (
      <Grid
        container
        spacing={3}
        ref={ref}
        {...draggableProps}
        {...dragHandleProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      >
        <Grid item xs={8}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <MoreVertIcon
              fontSize="small"
              style={{
                marginTop: '15px',
                marginRight: '5px',
              }}
              color="action"
            />
            <Autocomplete
              id="combo-box-demo"
              options={paths}
              getOptionLabel={(option) => option.name}
              fullWidth
              defaultValue={step.path}
              onChange={(event, value) => {
                step.path_id = value.id;
                step.path = value;
              }}
              renderInput={(params) => (
                <TextField {...params} label="Path que será clicado" />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <Input
            required
            id="clicks"
            name="clicks"
            label="Qtd. de Cliques"
            onBlur={() => {
              if (type === 'base') {
                calculateBaseBetTotalClicks();
              } else if (type === 'martingale') {
                calculateMartingalesTotalClicks();
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <Box mt={1} variant>
            <Button
              variant="contained"
              color="secondary"
              onClick={removeStepFunction}
              disabled={first}
            >
              Excluir
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  }

  function removeStep(step, type, martingale) {
    if (type === 'step') {
      const newSteps = playSteps.filter((playStep) => playStep !== step);
      setPlaySteps(newSteps);
      calculateBaseBetTotalClicks();
    } else if (type === 'martingale') {
      const oldMartingales = martingales;
      const newSteps = martingale.steps.filter((playStep) => playStep !== step);
      martingale.steps = newSteps;
      const index = oldMartingales.findIndex((m) => m === martingale);
      oldMartingales[index] = martingale;
      setMartingales([...oldMartingales]);
      calculateMartingalesTotalClicks([...oldMartingales]);
    }
  }

  function removeMartingale(martingale) {
    const newMartingales = martingales.filter((m) => m !== martingale);
    setMartingales([...newMartingales]);
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      playSteps,
      result.source.index,
      result.destination.index
    );

    setPlaySteps(items);
  }

  function onDragEndMartingale(result, index) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      martingales[index].steps,
      result.source.index,
      result.destination.index
    );

    martingales[index].steps = items;

    setMartingales(martingales);
  }

  function handleCheckboxStopWhenLosingARoundChange() {
    setStopWhenLosingARound(!stopWhenLosingARound);
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      {id && (
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {isDuplicate() ? `Cópia da Estratégia ${id}` : `Estratégia ${id}`}
        </Typography>
      )}

      <Form ref={formRef} onSubmit={onSubmit} noValidate>
        <Box>
          <Paper className={classes.paper}>
            <h3>Dados da Estratégia</h3>

            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Input
                  required
                  id="name"
                  name="name"
                  label="Nome interno da estratégia"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  required
                  id="commercial_name"
                  name="commercial_name"
                  label="Nome comercial da estratégia"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Select
                  name="status"
                  label="Situação da estratégia"
                  fullWidth
                  style={{ width: '100%' }}
                  onChange={handleStatusChange}
                  value={status}
                >
                  <MenuItem value="em_operacao">Em operação</MenuItem>
                  <MenuItem value="validada">Validada</MenuItem>
                  <MenuItem value="arquivada">Arquivada</MenuItem>
                  <MenuItem value="modelo">Estratégia Modelo</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Input
                  required
                  id="roulette_name"
                  name="roulette_name"
                  label="Nome da roleta"
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <Input
                  required
                  id="roulette_link"
                  name="roulette_link"
                  label="Link da Roleta"
                  fullWidth
                />
              </Grid>
            </Grid>
            <h3 style={{ marginTop: '28px' }}>
              Dados de Cobrança da estratégia
            </h3>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Input
                  required
                  id="stripe_product_id"
                  name="stripe_product_id"
                  label="ID Produto Stripe"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="infra_cost"
                  name="infra_cost"
                  label="Custo de Infraestrutura"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="strategy_cost"
                  name="robot_cost"
                  label="Custo da estratégia"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  required
                  id="percent_upon_profit"
                  name="percent_upon_profit"
                  label="Custo lucro percentual"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <h3 style={{ marginTop: '28px' }}>
              Dados financeiros da estratégia
            </h3>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Select
                  label="Risco da estratégia"
                  name="risk"
                  style={{ width: '100%' }}
                >
                  <MenuItem value="low">Baixo</MenuItem>
                  <MenuItem value="medium">Médio</MenuItem>
                  <MenuItem value="high">Alto</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={3}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="worst_scenario"
                  name="worst_scenario"
                  label="Pior Cenário"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="expected_scenario"
                  name="expected_scenario"
                  label="Cenário Esperado"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="best_scenario"
                  name="best_scenario"
                  label="Melhor Cenário"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="gain_goal"
                  defaultValue={0}
                  name="gain_goal"
                  label="Meta de ganho diário"
                  onChange={handleGainGoalChange}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Box mt={2}>
                  <TextField
                    required
                    fullWidth
                    disabled
                    value={percentUponGainGoal}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="loss_limit"
                  defaultValue={0}
                  name="loss_limit"
                  label="Limite de perda diário"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="monthly_gain_goal"
                  defaultValue={0}
                  name="monthly_gain_goal"
                  label="Meta de ganho mensal"
                  onChange={handleMonthlyGainGoalChange}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Box mt={2}>
                  <TextField
                    required
                    fullWidth
                    disabled
                    value={monthlyPercentUponGainGoal}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="monthly_loss_limit"
                  defaultValue={0}
                  name="monthly_loss_limit"
                  label="Limite de perda mensal"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="recommended_capital"
                  name="recommended_capital"
                  label="Capital recomendado"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  required
                  disabled
                  value={minimumCapital}
                  label="Capital Mínimo"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="last_year_income"
                  name="last_year_income"
                  label="Rendimento dos últimos 12 meses"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  required
                  id="last_year_assertiveness_rate"
                  name="last_year_assertiveness_rate"
                  label="Taxa de assertividade dos últimos 12 meses"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  style={{ marginTop: '5px' }}
                  control={
                    <Checkbox
                      checked={stopWhenLosingARound}
                      onChange={handleCheckboxStopWhenLosingARoundChange}
                      color="primary"
                    />
                  }
                  label="Parar ao perder?"
                />
              </Grid>
            </Grid>
            <h3>Dados operacionais da estratégia</h3>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Input
                  required
                  id="minimum_days"
                  name="minimum_days"
                  label="Dias mínimos"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  required
                  type="time"
                  id="start_hour"
                  name="start_hour"
                  label="Hora de início"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  required
                  type="time"
                  id="end_hour"
                  name="end_hour"
                  label="Hora de parada"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  required
                  id="delay"
                  defaultValue={200}
                  name="delay"
                  label="Delay entre os cliques (ms)"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="numbers"
                  options={filters}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  defaultValue={numbers}
                  value={numbers}
                  onChange={(event, value) => {
                    setNumbers(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Números da estratégia (filtro)"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="numbers_sequence"
                  options={filters}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  defaultValue={numbersInSequence}
                  value={numbersInSequence}
                  onChange={(event, value) => {
                    setNumbersInSequence(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Números para estratégia de sequência (filtro)"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  required
                  id="entry"
                  name="entry"
                  label="Entrada"
                  onChange={(e) => setEntryOn(parseInt(e.target.value, 10) + 1)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  disabled
                  value={entryOn}
                  label="Entra no"
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  disabled
                  value={goTo}
                  label="Vai até"
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Box mt={3}>
          <Paper className={classes.paper}>
            <h3>Parecer da validação da estratégia</h3>
            <Grid item xs={12}>
              <Input
                id="observation"
                multiline
                name="observation"
                label="Parecer"
                fullWidth
              />
            </Grid>
          </Paper>
        </Box>
        <Box mt={3}>
          <Paper className={classes.paper}>
            <h3>Passos da aposta inicial da estratégia</h3>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="base_bet_amount"
                  name="base_bet_amount"
                  label="Valor da aposta desta etapa"
                  onChange={calculateMinimumCapital}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Número total de clicks"
                  disabled
                  value={baseBetTotalClicks}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Tempo estimado de execução"
                  disabled
                  value={baseBetExecutionTime}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box mt={2} />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="baseBetStepsDroppable">
                {(provided, snapshot) => (
                  <Grid {...provided.droppableProps} ref={provided.innerRef}>
                    {playSteps.map((step, i) => (
                      <Draggable
                        key={step.id}
                        draggableId={String(step.id)}
                        index={i}
                      >
                        {(p, s) => (
                          <Scope
                            key={step.id}
                            path={`steps[${i}]`}
                            ref={p.innerRef}
                          >
                            {renderSteps(
                              playSteps.length === 1,
                              () => removeStep(step, 'step'),
                              'base',
                              p.innerRef,
                              p.draggableProps,
                              p.dragHandleProps,
                              p,
                              s,
                              step
                            )}
                          </Scope>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>

            <Box mt={3}>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={addNewPlayStep}
                >
                  Adicionar passo
                </Button>
              </Grid>
            </Box>
          </Paper>
          <h2>Início das configurações de Martingales</h2>
          {martingales.map((martingale, i) => (
            <Box key={martingale.order} mt={i !== 0 ? 5 : 0}>
              <Paper className={classes.paper}>
                <Scope path={`martingales[${i}]`}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <CurrencyInput
                        thousandSeparator="."
                        decimalSeparator=","
                        required
                        name="base_bet_amount"
                        label="Valor da aposta desta etapa"
                        onChange={calculateMinimumCapital}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        required
                        id="order"
                        name="order"
                        label="Ordem do martingale"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        required
                        id="order"
                        label="Número total de cliques"
                        value={martingalesBetTotalClicks[i] || 0}
                        disabled
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        required
                        id="order"
                        label="Tempo de execução"
                        value={martingalesExecutionTime[i] || `0s`}
                        disabled
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Box mt={1}>
                    <DragDropContext
                      onDragEnd={(result) => onDragEndMartingale(result, i)}
                    >
                      <Droppable droppableId={`droppable-${i}`}>
                        {(provided, snapshot) => (
                          <Grid
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {martingale.steps.map((step, idx) => (
                              <Draggable
                                key={step.id}
                                draggableId={String(step.id)}
                                index={idx}
                              >
                                {(p, s) => (
                                  <Scope
                                    key={step.id}
                                    path={`steps[${idx}]`}
                                    ref={p.innerRef}
                                  >
                                    {renderSteps(
                                      martingale.steps.length === 1,
                                      () =>
                                        removeStep(
                                          step,
                                          'martingale',
                                          martingale
                                        ),
                                      'martingale',
                                      p.innerRef,
                                      p.draggableProps,
                                      p.dragHandleProps,
                                      p,
                                      s,
                                      step
                                    )}
                                  </Scope>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </Grid>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                  <Box mt={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => addNewMartingaleStep(i)}
                        >
                          Adicionar passo
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          style={{ float: 'right' }}
                          color="secondary"
                          variant="outlined"
                          onClick={() => removeMartingale(martingale)}
                        >
                          Excluir martingale
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Scope>
              </Paper>
            </Box>
          ))}

          <Box mt={2}>
            <Grid item xs={12}>
              <Button
                color="primary"
                onClick={addNewMartingale}
                variant="outlined"
              >
                Adicionar martingale
              </Button>
            </Grid>
          </Box>
        </Box>
        <Fab
          onClick={() => formRef.current.submitForm()}
          color="primary"
          aria-label="add"
          variant="extended"
          className={classes.fab}
        >
          <Save /> &nbsp; Salvar Estratégia
        </Fab>
      </Form>
    </Container>
  );
}

export default StrategyFormV2;
