import { addDays, format, parseISO } from 'date-fns';
import { DatePicker } from '@material-ui/pickers';
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import { Select } from 'unform-material-ui';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useParams, Link } from 'react-router-dom';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import Input from '../../../components/Input';
import api from '../../../services/api';
import { schema } from './schema';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function RobotForm() {
  const formRef = useRef(null);
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [current_period_start, setCurrentPeriodStart] = useState(new Date());
  const [current_period_end, setCurrentPeriodEnd] = useState(new Date());
  const [goals, setGoals] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [collect, setCollect] = useState(false);

  function fetchStrategies() {
    api
      .get('betfair/greyhound/strategies', {
        params: {
          status: 'elegible',
        },
      })
      .then((response) => setStrategies(response.data));
  }

  useEffect(() => {
    fetchStrategies();
    const defaultData = {
      robot_status: 'started',
      payment_status: 'paid',
      subscription_external_id: 'subs_123',
      login: null,
      password: null,
      host_name: null,
      betfair_api_key: null,
      robot_secondary_status: 'none',
    };

    if (id) {
      api.get(`betfair/greyhound/robots/${id}`).then((response) => {
        setCurrentPeriodStart(response.data.current_period_start);
        setCurrentPeriodEnd(response.data.current_period_end);
        if (!response.data.robot_secondary_status) {
          response.data.robot_secondary_status = 'none';
        }
        formRef.current.setData(response.data);
        setGoals(response.data.goals);
        setCollect(response.data.is_collector);
      });
    } else {
      formRef.current.setData(defaultData);
      setCurrentPeriodStart(new Date());
      setCurrentPeriodEnd(addDays(new Date(), 30));
    }
  }, [id]);

  async function onSubmit(data) {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      if (data.robot_secondary_status === 'none') {
        data.robot_secondary_status = null;
      }

      if (id) {
        await api.put(`betfair/greyhound/robots/${id}`, {
          ...data,
          host_name: data.host_name || null,
          current_period_start,
          current_period_end,
          is_collector: collect,
        });
      } else {
        await api.post('betfair/greyhound/robots', {
          ...data,
          host_name: data.host_name || null,
          current_period_start,
          current_period_end,
          is_collector: collect,
        });
      }

      history.push('/betfair/greyhounds/robots');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const formatDate = (date) => format(parseISO(date), 'dd/MM/yyyy HH:mm:ss');

  async function handleCreateInstance() {
    alert('Não implementado!'); // eslint-disable-line
  }

  async function handleTerminateInstance() {
    alert('Não implementado!'); // eslint-disable-line
  }

  async function handleRestartProject() {
    alert('Não implementado!'); // eslint-disable-line
  }

  async function handleUpdateProject() {
    alert('Não implementado!'); // eslint-disable-line
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Box mb={5}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={10} />
            <Grid item xs={2}>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      {...bindTrigger(popupState)}
                      style={{ float: 'right' }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          handleCreateInstance();
                          popupState.close();
                        }}
                      >
                        Criar instância
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleTerminateInstance();
                          popupState.close();
                        }}
                      >
                        Terminar instância
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleRestartProject();
                          popupState.close();
                        }}
                      >
                        Reiniciar aplicação
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleUpdateProject();
                          popupState.close();
                        }}
                      >
                        Atualizar instância
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </PopupState>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Form ref={formRef} onSubmit={onSubmit} noValidate>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Input required id="login" name="login" label="Login" fullWidth />
            </Grid>
            <Grid item xs={2}>
              <Input
                required
                id="password"
                name="password"
                label="Senha"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                required
                id="host_name"
                name="host_name"
                label="IP Interno"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                required
                id="robot_status"
                name="robot_status"
                label="Status"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                required
                id="payment_status"
                name="payment_status"
                label="Status de pagamento"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="subscription_external_id"
                name="subscription_external_id"
                label="ID Stripe"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <DatePicker
                value={current_period_start}
                onChange={setCurrentPeriodStart}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                id="date-picker-inline"
                label="Período Início"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <DatePicker
                value={current_period_end}
                onChange={setCurrentPeriodEnd}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                id="date-picker-inline-2"
                label="Período Fim"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                label="Estratégia"
                name="strategy_id"
                style={{ width: '100%' }}
              >
                {strategies.map((strategy) => (
                  <MenuItem key={strategy.id} value={strategy.id}>
                    {strategy.id} - {strategy.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="betfair_api_key"
                name="betfair_api_key"
                label="Betfair API Key"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                label="Status de operação"
                name="robot_secondary_status"
                style={{ width: '100%' }}
              >
                <MenuItem value="none">Nenhum</MenuItem>
                <MenuItem value="paused_by_time">
                  Horário máximo atingido
                </MenuItem>
                <MenuItem value="reached_gain_goal">
                  Meta diária atingida
                </MenuItem>
                <MenuItem value="reached_loss_limit">
                  Limite diário de perda atingido
                </MenuItem>
                <MenuItem value="reached_monthly_gain_goal">
                  Meta mensal atingida
                </MenuItem>
                <MenuItem value="reached_monthly_loss_limit">
                  Limite mensal de perda atingido
                </MenuItem>
                <MenuItem value="stopped_by_one_lose">Perdeu 1x hoje</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Paper>

        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary">
            Salvar
          </Button>
        </Box>
      </Form>
    </Container>
  );
}

export default RobotForm;
