import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BarChartIcon from '@material-ui/icons/BarChart';

import List from '@material-ui/core/List';
import { BrowserRouter, Link } from 'react-router-dom';
import {
  ExpandLess,
  ExpandMore,
  FilterList,
  PlayArrow,
  ListOutlined,
  SyncAlt,
  BugReport,
  Timer,
  AttachMoney,
  Undo,
  Repeat,
  Timeline,
  Computer,
  People,
} from '@material-ui/icons';
import { Collapse } from '@material-ui/core';
import Routes from './routes';
import { useAuth } from './hooks/auth';

import Logo from './assets/firebet_logo.png';

const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  logo: {
    width: '170px',
    marginLeft: '12px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  list: {
    overflowY: 'scroll',
    height: '93vh',
  },
}));

export default function App() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [reportsMenuOpen, setReportsMenuOpen] = useState(false);
  const [reportsCrashOpen, setReportsCrashOpen] = useState(false);
  const [isCasinoCalculatorsOpened, setIsCasinoCalculatorsOpened] = useState(
    false
  );

  const { user } = useAuth();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <BrowserRouter>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Firebot - Admin
            </Typography> */}
            <div className={classes.title} />
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <img src={Logo} alt="Logo do firebet" className={classes.logo} />
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List className={classes.list}>
            {user && user.profile === 'master' && (
              <>
                <ListItem button component={Link} to="/all-reports">
                  <ListItemIcon>
                    <Computer />
                  </ListItemIcon>
                  <ListItemText primary="Status de processamento" />
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemIcon />
                  <ListItemText primary="Administração" />
                </ListItem>
                <ListItem button component={Link} to="/users">
                  <ListItemIcon>
                    <People />
                  </ListItemIcon>
                  <ListItemText primary="Clientes" />
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemIcon />
                  <ListItemText primary="Betfair Roleta" />
                </ListItem>
                <ListItem button component={Link} to="/strategies">
                  <ListItemIcon>
                    <PlayArrow />
                  </ListItemIcon>
                  <ListItemText primary="Estratégias roleta" />
                </ListItem>
                <ListItem button component={Link} to="/robots">
                  <ListItemIcon>
                    <ListOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Robôs" />
                </ListItem>
                <ListItem button component={Link} to="/paths">
                  <ListItemIcon>
                    <SyncAlt />
                  </ListItemIcon>
                  <ListItemText primary="Controle de paths" />
                </ListItem>
                <ListItem
                  button
                  onClick={() =>
                    setIsCasinoCalculatorsOpened(!isCasinoCalculatorsOpened)
                  }
                >
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Calculadoras" />
                  {isCasinoCalculatorsOpened ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={isCasinoCalculatorsOpened}
                  timeout="auto"
                  unmountOnExit
                >
                  <ListItem button component={Link} to="/calculator">
                    <ListItemIcon>
                      <BugReport />
                    </ListItemIcon>
                    <ListItemText primary="Calculadora de Estratégias" />
                  </ListItem>
                  <ListItem button component={Link} to="/calculator/v2">
                    <ListItemIcon>
                      <BugReport />
                    </ListItemIcon>
                    <ListItemText primary="Calculadora de Estratégias V2" />
                  </ListItem>
                  <ListItem button component={Link} to="/calculator/v3">
                    <ListItemIcon>
                      <BugReport />
                    </ListItemIcon>
                    <ListItemText primary="Calculadora de Estratégias V3" />
                  </ListItem>
                </Collapse>
                <ListItem
                  button
                  onClick={() => setReportsMenuOpen(!reportsMenuOpen)}
                >
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Relatórios" />
                  {reportsMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <ListItem button component={Link} to="/filters">
                  <ListItemIcon>
                    <FilterList />
                  </ListItemIcon>
                  <ListItemText primary="Gestão de filtros" />
                </ListItem>
                <Collapse in={reportsMenuOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button component={Link} to="/">
                      <ListItemIcon>
                        <Timer />
                      </ListItemIcon>
                      <ListItemText primary="Rodadas" />
                    </ListItem>
                    <ListItem button component={Link} to="/financial">
                      <ListItemIcon>
                        <AttachMoney />
                      </ListItemIcon>
                      <ListItemText primary="Relatório de Desempenho" />
                    </ListItem>
                    <ListItem button component={Link} to="/reportV2">
                      <ListItemIcon>
                        <Repeat />
                      </ListItemIcon>
                      <ListItemText primary="Relatório de eventos" />
                    </ListItem>
                  </List>
                </Collapse>
                <Divider />
                {/* <ListItem button>
                  <ListItemIcon />
                  <ListItemText primary="Corrida de galgos" />
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemIcon />
                  <ListItemText primary="Bet 365" />
                </ListItem>
                <ListItem button component={Link} to="/greyhounds/races">
                  <ListItemIcon>
                    <Timer />
                  </ListItemIcon>
                  <ListItemText primary="Resultados corridas" />
                </ListItem>
                <ListItem button component={Link} to="/greyhounds/calculator">
                  <ListItemIcon>
                    <BugReport />
                  </ListItemIcon>
                  <ListItemText primary="Calculadora de Estratégias" />
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemIcon />
                  <ListItemText primary="Betfair" />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/betfair/greyhounds/races"
                >
                  <ListItemIcon>
                    <Timer />
                  </ListItemIcon>
                  <ListItemText primary="Resultados corridas" />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/betfair/greyhounds/calculator"
                >
                  <ListItemIcon>
                    <BugReport />
                  </ListItemIcon>
                  <ListItemText primary="Calculadora de Estratégias" />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/betfair/greyhounds/strategies"
                >
                  <ListItemIcon>
                    <PlayArrow />
                  </ListItemIcon>
                  <ListItemText primary="Estratégias" />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/betfair/greyhounds/robots"
                >
                  <ListItemIcon>
                    <ListOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Robôs" />
                </ListItem> */}
                <Divider />
                <ListItem button>
                  <ListItemIcon />
                  <ListItemText primary="Blaze - Crash" />
                </ListItem>
                <ListItem button component={Link} to="/blaze/crash/strategies">
                  <ListItemIcon>
                    <PlayArrow />
                  </ListItemIcon>
                  <ListItemText primary="Estratégias Crash" />
                </ListItem>
                <ListItem button component={Link} to="/blaze/crash/robots">
                  <ListItemIcon>
                    <ListOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Robôs" />
                </ListItem>
                <ListItem button component={Link} to="/blaze/crash/calculator">
                  <ListItemIcon>
                    <BugReport />
                  </ListItemIcon>
                  <ListItemText primary="Calculadora" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => setReportsCrashOpen(!reportsCrashOpen)}
                >
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Relatórios" />
                  {reportsCrashOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={reportsCrashOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      component={Link}
                      to="/blaze/crash/reports/events"
                    >
                      <ListItemIcon>
                        <Repeat />
                      </ListItemIcon>
                      <ListItemText primary="Relatório de eventos" />
                    </ListItem>
                    <ListItem button component={Link} to="/blaze/crash">
                      <ListItemIcon>
                        <Timer />
                      </ListItemIcon>
                      <ListItemText primary="Resultados" />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/blaze/crash/graph/odd"
                    >
                      <ListItemIcon>
                        <Timeline />
                      </ListItemIcon>
                      <ListItemText primary="Gráfico por odd" />
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Routes />
        </main>
      </div>
    </BrowserRouter>
  );
}
