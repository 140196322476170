import React, { useCallback } from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Box, Button, Typography } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import api from '../../services/api';

const mappedServices = [
  {
    id: 'betfair_greyhound_calculator',
    name: '[Betfair] Calculadora Galgo',
  },
  {
    id: 'casino_calculator_v1',
    name: '[Betfair] Cassino Calculadora V1',
  },
  {
    id: 'casino_calculator_v2',
    name: '[Betfair] Cassino Calculadora V2',
  },
  {
    id: 'casino_calculator_v3',
    name: '[Betfair] Cassino Calculadora V3',
  },
  {
    id: 'casino_events_report',
    name: '[Betfair] Cassino Relatório de eventos',
  },
  {
    id: 'blaze_crash_calculator',
    name: '[Blaze] Calculadora Crash',
  },
  {
    id: 'blaze_crash_events_report',
    name: '[Blaze] Crash Relatório de eventos',
  },
];

const mappedStatus = [
  {
    id: 'active',
    name: 'Processando',
  },
  {
    id: 'waiting',
    name: 'Aguardando Processando',
  },
  {
    id: 'completed',
    name: 'Completo',
  },
  {
    id: 'failed',
    name: 'Falhou',
  },
];

const columns = [
  { id: 'id', label: '#' },
  {
    id: 'service',
    label: 'Serviço',
    format: (value) => mappedServices.find((item) => item.id === value).name,
  },
  {
    id: 'createdAt',
    label: 'Solicitação',
    format: (value) =>
      value ? format(parseISO(value), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    id: 'processed_at',
    label: 'Início processamento',
    format: (value) =>
      value ? format(parseISO(value), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    id: 'finished_at',
    label: 'Finalização',
    format: (value) =>
      value ? format(parseISO(value), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    id: 'status',
    label: 'Status',
    format: (value) => mappedStatus.find((item) => item.id === value).name,
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function AllReportsStatus() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const classes = useStyles();

  const fetchJobs = useCallback(() => {
    api
      .get('jobs', {
        params: {
          count: rowsPerPage,
          page,
        },
      })
      .then((response) => {
        setRows(response.data.rows);
        setCount(response.data.count);
      });
  }, [rowsPerPage, page]);

  React.useEffect(() => {
    fetchJobs();
  }, [rowsPerPage, page, fetchJobs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
        Status dos jobs
      </Typography>
      <Box>
        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell align="right">
                      <Button
                        style={{ marginLeft: '10px' }}
                        color="primary"
                        variant="outlined"
                        onClick={() => {}}
                      >
                        Visualizar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 250, 500, 1000]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Registros por página"
          />
        </Paper>
      </Box>
    </Container>
  );
}

export default AllReportsStatus;
