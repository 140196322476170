import {
  Box,
  Container,
  Fab,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { Select } from 'unform-material-ui';
import { Save } from '@material-ui/icons';
import Input from '../../../components/Input';
import api from '../../../services/api';
import { schema } from './schema';

import CurrencyInput from '../../../components/CurrencyInput';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(5),
    display: 'flex',
  },
}));

function StrategyForm() {
  const formRef = useRef(null);
  const classes = useStyles();

  const [greyhound1, setGreyhound1] = useState(false);
  const [greyhound2, setGreyhound2] = useState(false);
  const [greyhound3, setGreyhound3] = useState(false);
  const [greyhound4, setGreyhound4] = useState(false);
  const [greyhound5, setGreyhound5] = useState(false);
  const [greyhound6, setGreyhound6] = useState(false);
  const [percentUponGainGoal, setPercentUponGainGoal] = useState(0);
  const [monthlyPercentUponGainGoal, setMonthlyPercentUponGainGoal] = useState(
    0
  );
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  function initializeGreyhounds(strategy) {
    strategy.greyhounds
      .split(',')
      .map((number) => +number)
      .forEach((item) => {
        switch (item) {
          case 1:
            setGreyhound1(true);
            break;
          case 2:
            setGreyhound2(true);
            break;
          case 3:
            setGreyhound3(true);
            break;
          case 4:
            setGreyhound4(true);
            break;
          case 5:
            setGreyhound5(true);
            break;
          case 6:
            setGreyhound6(true);
            break;

          default:
            break;
        }
      });
  }

  useEffect(() => {
    if (id) {
      api.get(`betfair/greyhound/strategies/${id}`).then((strategyResponse) => { // eslint-disable-line
        const strategy = {
          ...strategyResponse.data,
          membership_fee: strategyResponse.data.membership_fee / 100,
          fixed_cost: strategyResponse.data.fixed_cost / 100,
          minimum_capital: strategyResponse.data.minimum_capital / 100,
          percent_upon_profit: strategyResponse.data.percent_upon_profit / 100,
          gain_goal: strategyResponse.data.gain_goal / 100,
          monthly_gain_goal: strategyResponse.data.monthly_gain_goal / 100,
          loss_limit: strategyResponse.data.loss_limit / 100,
          monthly_loss_limit: strategyResponse.data.monthly_loss_limit / 100,
          recommended_capital: strategyResponse.data.recommended_capital / 100,
          expected_profit: strategyResponse.data.expected_profit / 100,
        };

        initializeGreyhounds(strategy);

        formRef.current.setData(strategy);
      });
    }
  }, []); //eslint-disable-line

  function handleGainGoalChange() {
    const { gain_goal, minimum_capital } = formRef.current.getData();

    if (gain_goal && gain_goal > 0 && minimum_capital && minimum_capital > 0) {
      const percent = Number((gain_goal * 100) / minimum_capital).toFixed(2);
      setPercentUponGainGoal(percent);
    }
  }

  function handleMonthlyGainGoalChange() {
    const { monthly_gain_goal, minimum_capital } = formRef.current.getData();

    if (
      monthly_gain_goal &&
      monthly_gain_goal > 0 &&
      minimum_capital &&
      minimum_capital > 0
    ) {
      const percent = Number(
        (monthly_gain_goal * 100) / minimum_capital
      ).toFixed(2);
      setMonthlyPercentUponGainGoal(percent);
    }
  }

  async function onSubmit(data) {
    const greyhounds = [];
    if (greyhound1) greyhounds.push(1);
    if (greyhound2) greyhounds.push(2);
    if (greyhound3) greyhounds.push(3);
    if (greyhound4) greyhounds.push(4);
    if (greyhound5) greyhounds.push(5);
    if (greyhound6) greyhounds.push(6);

    if (greyhounds.length === 0) {
      alert('Marque ao menos 1 galgo'); // eslint-disable-line
      return;
    }

    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      const payload = {
        ...data,
        membership_fee: data.membership_fee * 100,
        fixed_cost: data.fixed_cost * 100,
        percent_upon_profit: data.percent_upon_profit * 100,
        minimum_capital: data.minimum_capital * 100,
        gain_goal: data.gain_goal * 100,
        monthly_gain_goal: data.monthly_gain_goal * 100,
        loss_limit: data.loss_limit * 100,
        monthly_loss_limit: data.monthly_loss_limit * 100,
        recommended_capital: data.recommended_capital * 100,
        expected_profit: data.expected_profit * 100,
        greyhounds: greyhounds.toString(),
      };

      const { newVersion } = queryString.parse(location.search);

      if (newVersion) {
        await api.post(
          `betfair/greyhound/strategies/${id}/new-version`,
          payload
        );
      } else {
        await api.post('betfair/greyhound/strategies', payload);
      }

      history.push('/betfair/greyhounds/strategies');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function isDuplicate() {
    const qs = queryString.parse(location.search);
    return qs.duplicate;
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      {id && (
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {isDuplicate() ? `Cópia da Estratégia ${id}` : `Estratégia ${id}`}
        </Typography>
      )}

      <Form ref={formRef} onSubmit={onSubmit} noValidate>
        <Box>
          <Paper className={classes.paper}>
            <h3>Dados da Estratégia</h3>

            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Input
                  required
                  id="name"
                  name="name"
                  label="Nome da estratégia"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Select
                  name="status"
                  label="Situação da estratégia"
                  fullWidth
                  defaultValue="in_operation"
                  style={{ width: '100%' }}
                >
                  <MenuItem value="in_operation">Em operação</MenuItem>
                  <MenuItem value="validated">Validada</MenuItem>
                  <MenuItem value="archived">Arquivada</MenuItem>
                  <MenuItem value="template">Estratégia Modelo</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <h3 style={{ marginTop: '28px' }}>
              Dados de Cobrança da estratégia
            </h3>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Input
                  required
                  id="stripe_product_id"
                  name="stripe_product_id"
                  label="ID Produto Stripe"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  required
                  id="membership_fee"
                  name="membership_fee"
                  label="Custo adesão"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  required
                  id="fixed_cost"
                  name="fixed_cost"
                  label="Custo fixo"
                  value={100}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  required
                  id="percent_upon_profit"
                  name="percent_upon_profit"
                  label="Custo lucro percentual"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <h3 style={{ marginTop: '28px' }}>
              Dados financeiros da estratégia
            </h3>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="gain_goal"
                  defaultValue={0}
                  name="gain_goal"
                  label="Meta de ganho diário"
                  onChange={handleGainGoalChange}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Box mt={2}>
                  <TextField
                    required
                    fullWidth
                    disabled
                    value={percentUponGainGoal}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="monthly_gain_goal"
                  defaultValue={0}
                  name="monthly_gain_goal"
                  label="Meta de ganho mensal"
                  onChange={handleMonthlyGainGoalChange}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Box mt={2}>
                  <TextField
                    required
                    fullWidth
                    disabled
                    value={monthlyPercentUponGainGoal}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="loss_limit"
                  defaultValue={0}
                  name="loss_limit"
                  label="Limite de perda diário"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="monthly_loss_limit"
                  defaultValue={0}
                  name="monthly_loss_limit"
                  label="Limite de perda mensal"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="minimum_capital"
                  name="minimum_capital"
                  label="Capital mínimo"
                  fullWidth
                  onChange={() => {
                    handleGainGoalChange();
                    handleMonthlyGainGoalChange();
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="recommended_capital"
                  name="recommended_capital"
                  label="Capital recomendado"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <h3>Dados operacionais da estratégia</h3>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Input
                  required
                  id="minimum_days"
                  name="minimum_days"
                  label="Dias mínimos"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  required
                  type="time"
                  id="start_hour"
                  name="start_hour"
                  label="Hora de início"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  required
                  type="time"
                  id="end_hour"
                  name="end_hour"
                  label="Hora de parada"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Box mt={3}>
          <Paper className={classes.paper}>
            <h3>Passos da aposta inicial da estratégia</h3>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  id="expected_profit"
                  name="expected_profit"
                  defaultValue={0}
                  label="Valor do lucro esperado"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  required
                  defaultValue={0}
                  id="martingale_multiplier_factor"
                  name="martingale_multiplier_factor"
                  label="Fator multiplicador do martingale"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  required
                  defaultValue={0}
                  id="martingale_goes_to"
                  name="martingale_goes_to"
                  label="Martingale via até"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={greyhound1}
                      onChange={() => setGreyhound1(!greyhound1)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Galgo 1"
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={greyhound2}
                      onChange={() => setGreyhound2(!greyhound2)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Galgo 2"
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={greyhound3}
                      onChange={() => setGreyhound3(!greyhound3)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Galgo 3"
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={greyhound4}
                      onChange={() => setGreyhound4(!greyhound4)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Galgo 4"
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={greyhound5}
                      onChange={() => setGreyhound5(!greyhound5)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Galgo 5"
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={greyhound6}
                      onChange={() => setGreyhound6(!greyhound6)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Galgo 6"
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Fab
          onClick={() => formRef.current.submitForm()}
          color="primary"
          aria-label="add"
          variant="extended"
          className={classes.fab}
        >
          <Save /> &nbsp; Salvar Estratégia
        </Fab>
      </Form>
    </Container>
  );
}

export default StrategyForm;
