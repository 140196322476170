import { addDays, format, parseISO } from 'date-fns';
import { DatePicker } from '@material-ui/pickers';
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  IconButton,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { Select } from 'unform-material-ui';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useParams, Link } from 'react-router-dom';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import { Save } from '@material-ui/icons';
import Input from '../../components/Input';
import api from '../../services/api';
import { schema } from './schema';
import {
  handleCreateInstance,
  handleRestartProject,
  handleTerminateInstance,
  handleUpdateProject,
} from '../../utils/jenkins';

import { currencyFormat } from '../../utils/formatter';

const table_name = 'robots';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function SectionTitle({ title }) {
  return (
    <Grid item xs={12}>
      <h3 style={{ margin: 0 }}>{title}</h3>
    </Grid>
  );
}

function RobotForm() {
  const formRef = useRef(null);
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [current_period_start, setCurrentPeriodStart] = useState(new Date());
  const [current_period_end, setCurrentPeriodEnd] = useState(new Date());
  const [goals, setGoals] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [collect, setCollect] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [environment, setEnvironment] = useState(null);
  const [dailyOperations, setDailyOperations] = useState([]);
  const [isHeadless, setIsHeadless] = useState(true);

  function fetchStrategies() {
    api
      .get('admin/strategies', {
        params: {
          status: 'elegible',
        },
      })
      .then((response) => setStrategies(response.data));
  }

  function fetchCustomers() {
    api.get('admin/users').then((response) => setCustomers(response.data));
  }

  function fetchDailyOperations(robot_id) {
    api
      .get(`admin/robots/${robot_id}/history`)
      .then((response) => setDailyOperations(response.data));
  }

  useEffect(() => {
    if (environment === 'cloud') {
      setIsHeadless(true);
    }
  }, [environment]);

  useEffect(() => {
    fetchStrategies();
    fetchCustomers();
    const defaultData = {
      platform: 'Betfair',
      type: 'Roleta',
      robot_status: 'started',
      payment_status: 'paid',
      subscription_external_id: 'subs_123',
      login: null,
      password: null,
      host_name: null,
      betfair_api_key: null,
    };

    if (id) {
      api.get(`admin/robots/${id}`).then((response) => {
        setCurrentPeriodStart(response.data.current_period_start);
        setCurrentPeriodEnd(response.data.current_period_end);
        if (!response.data.robot_secondary_status) {
          response.data.robot_secondary_status = 'none';
        }
        formRef.current.setData(response.data);
        setGoals(response.data.goals);
        setCollect(response.data.is_collector);
        setIsHeadless(response.data.is_headless);
        setEnvironment(response.data.environment);
        fetchDailyOperations(id);
      });
    } else {
      formRef.current.setData(defaultData);
      setCurrentPeriodStart(new Date());
      setCurrentPeriodEnd(addDays(new Date(), 30));
      setEnvironment('cloud');
    }
  }, [id]);

  async function onSubmit(data) {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      if (data.robot_secondary_status === 'none') {
        data.robot_secondary_status = null;
      }

      data.environment = environment;

      if (id) {
        await api.put(`admin/robots/${id}`, {
          ...data,
          host_name: data.host_name || null,
          current_period_start,
          current_period_end,
          is_collector: collect,
          is_headless: isHeadless,
        });
      } else {
        await api.post('admin/robots', {
          ...data,
          host_name: data.host_name || null,
          current_period_start,
          current_period_end,
          is_collector: collect,
          is_headless: isHeadless,
        });
      }

      history.push('/robots');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const formatDate = (date) => format(parseISO(date), 'dd/MM/yyyy HH:mm:ss');

  function handleCheckboxCollectChange() {
    setCollect(!collect);
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Box mb={5}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={collect}
                    onChange={handleCheckboxCollectChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Robô de coleta"
              />
            </Grid>
            <Grid item xs={2}>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      {...bindTrigger(popupState)}
                      style={{ float: 'right' }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          handleCreateInstance({ robot_id: id, table_name });
                          popupState.close();
                        }}
                      >
                        Criar instância
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleTerminateInstance({ robot_id: id, table_name });
                          popupState.close();
                        }}
                      >
                        Terminar instância
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleRestartProject({ robot_id: id, table_name });
                          popupState.close();
                        }}
                      >
                        Reiniciar aplicação
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleUpdateProject({ robot_id: id, table_name });
                          popupState.close();
                        }}
                      >
                        Atualizar instância
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </PopupState>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Form ref={formRef} onSubmit={onSubmit} noValidate>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <SectionTitle title="Plataforma de operação" />
            <Grid item xs={6}>
              <Input
                required
                id="platform"
                name="platform"
                label="Casa de aposta"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                required
                id="type"
                name="type"
                label="Tipo de robô"
                fullWidth
              />
            </Grid>
            <SectionTitle title="Dados de acesso da conta" />
            <Grid item xs={4}>
              <Input required id="login" name="login" label="Login" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="password"
                name="password"
                label="Senha"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="betfair_api_key"
                name="betfair_api_key"
                label="Betfair API Key"
                fullWidth
              />
            </Grid>
            <SectionTitle title="Dados financeiros do robô" />
            <Grid item xs={6}>
              <Select label="Cliente" name="user_id" style={{ width: '100%' }}>
                {customers.map((customer) => (
                  <MenuItem key={customer.id} value={customer.id}>
                    {customer.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={3}>
              <Input
                required
                id="subscription_external_id"
                name="subscription_external_id"
                label="ID Stripe"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                required
                id="robot_status"
                name="robot_status"
                label="Status"
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                value={current_period_start}
                onChange={setCurrentPeriodStart}
                style={{ width: '100%' }}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                id="date-picker-inline"
                label="Período Início"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                value={current_period_end}
                onChange={setCurrentPeriodEnd}
                style={{ width: '100%' }}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                id="date-picker-inline-2"
                label="Período Fim"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="payment_status"
                name="payment_status"
                label="Status de pagamento"
                disabled
                fullWidth
              />
            </Grid>
            <SectionTitle title="Dados de infraestrutura" />
            <Grid item xs={environment === 'local' ? 6 : 12}>
              {environment && (
                <RadioGroup row defaultValue={environment}>
                  <FormControlLabel
                    value="cloud"
                    control={<Radio color="primary" />}
                    label="Rodar em nuvem"
                    onChange={(e) => setEnvironment(e.target.value)}
                  />
                  <FormControlLabel
                    value="local"
                    control={<Radio color="primary" />}
                    label="Rodar localmente"
                    onChange={(e) => setEnvironment(e.target.value)}
                  />
                </RadioGroup>
              )}
            </Grid>
            {environment === 'local' && (
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isHeadless}
                      onChange={() => setIsHeadless((prev) => !prev)}
                      name="headless"
                      color="primary"
                    />
                  }
                  label="Headless"
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <Input
                required
                id="host_name"
                name="host_name"
                label="IP interno da máquina em nuvem"
                fullWidth
              />
            </Grid>
            <SectionTitle title="Dados da estratégia" />
            <Grid item xs={6}>
              <Select
                label="Selecione a estratégia"
                name="strategy_id"
                style={{ width: '100%' }}
              >
                {strategies.map((strategy) => (
                  <MenuItem key={strategy.id} value={strategy.id}>
                    {strategy.id} - {strategy.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Status de operação"
                name="robot_secondary_status"
                style={{ width: '100%' }}
              >
                <MenuItem value="none">Nenhum</MenuItem>
                <MenuItem value="paused_by_time">
                  Horário máximo atingido
                </MenuItem>
                <MenuItem value="reached_gain_goal">
                  Meta diária atingida
                </MenuItem>
                <MenuItem value="reached_loss_limit">
                  Limite diário de perda atingido
                </MenuItem>
                <MenuItem value="reached_monthly_gain_goal">
                  Meta mensal atingida
                </MenuItem>
                <MenuItem value="reached_monthly_loss_limit">
                  Limite mensal de perda atingido
                </MenuItem>
                <MenuItem value="stopped_by_one_lose">Perdeu 1x hoje</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Paper>

        <Box mt={2} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit" variant="contained" color="primary">
            <Save />
            &nbsp; Salvar robô
          </Button>
        </Box>

        <Box>
          <Typography
            style={{ margin: '30px 0' }}
            variant="h6"
            gutterBottom
            align="center"
          >
            Extrato diário de operações
          </Typography>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell align="center">Lucro</TableCell>
                  <TableCell align="right">Saldo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dailyOperations.map((dailyOperation) => (
                  <tr key={dailyOperation.id}>
                    <TableCell scope="row">
                      {format(parseISO(dailyOperation.day), 'dd/MM/yyyy')}
                    </TableCell>
                    <TableCell align="center">
                      {currencyFormat(dailyOperation.day_profit / 100)}
                    </TableCell>
                    <TableCell align="right">
                      {currencyFormat(dailyOperation.balance / 100)}
                    </TableCell>
                  </tr>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box mt={4}>
          <Typography
            variant="h6"
            gutterBottom
            style={{ margin: '30px 0' }}
            align="center"
          >
            Limites financeiros diários atingidos
          </Typography>

          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Início operação</TableCell>
                  <TableCell>Fim operação</TableCell>
                  <TableCell>Estratégia</TableCell>
                  <TableCell>Tipo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {goals.map((goal) => (
                  <TableRow key={goal.id}>
                    <TableCell>
                      {formatDate(goal.operation_started_at)}
                    </TableCell>
                    <TableCell>{formatDate(goal.createdAt)}</TableCell>
                    <TableCell>
                      <Link
                        to={`/strategy/form/${goal.strategy_id}?newVersion=true`}
                      >
                        {goal.strategy_id}
                      </Link>
                    </TableCell>
                    <TableCell>{goal.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Form>
    </Container>
  );
}

export default RobotForm;
