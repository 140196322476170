import React, { useEffect } from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  minWidth: 650,
}));

function Report() {
  const [report, setReport] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();

  useEffect(() => {
    api.get('rounds/grouped-with-all-filters').then(async (response) => {
      const sorted = response.data.sort((a, b) => {
        const x = a.report.sort((c, d) => d.result.length - c.result.length);
        const y = b.report.sort((c, d) => d.result.length - c.result.length);
        return y[0].result.length - x[0].result.length;
      });

      const result = sorted.map((s) => ({
        name: s.filter_name,
        smallest_sequence: s.report[s.report.length - 1], // ordenar pela quantidade ou data ?
        biggest_sequence: s.report[0],
      }));

      setReport(result);

      setLoading(false);
    });
  }, []);

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          Relatório Estatístico
        </Typography>
        <Box mt={5}>
          {loading ? (
            <p>Carregando...</p>
          ) : (
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Item</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Filtro</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Sequência Menor</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Sequência Maior</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.map((row, _) => (
                    <TableRow key={row.name}>
                      <TableCell>{_ + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {row.smallest_sequence.result.length}x -{' '}
                        {row.smallest_sequence.date}
                      </TableCell>
                      <TableCell>
                        {row.biggest_sequence.result.length}x -{' '}
                        {row.biggest_sequence.date}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Container>
    </>
  );
}

export default Report;
