import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../hooks/auth';

/*eslint-disable */

export default function RouteWrapper({
  component: Component, // eslint-disable
  isPrivate,
  master,
  ...rest
}) {
  const { user } = useAuth();

  if ((!user && isPrivate) || (master && user.profile !== 'master')) {
    return <Redirect to="forbidden" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}
