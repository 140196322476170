import React, { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  minWidth: 650,
}));

function ReportV2() {
  const [filter, setFilter] = React.useState('');
  const [start_date, setStartDate] = React.useState(null);
  const [end_date, setEndDate] = React.useState(null);
  const [checked, setChecked] = useState(false);
  const [report, setReport] = React.useState([]);
  const [maximumColumns, setMaximumColumns] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    api.get('admin/filters').then((response) => {
      setFilters(response.data);
    });
  }, []);

  function handleFilter() {
    if (!start_date || !end_date) {
      // eslint-disable-next-line
      alert('Informe as datas do filtro');
      return;
    }
    if (!filter) {
      // eslint-disable-next-line
      alert('Selecione un filtro')
      return;
    }
    setLoading(true);
    api
      .get('rounds/grouped', {
        params: {
          start_date,
          end_date,
          filter: filter.numbers,
          group_by_hour: checked,
          closed_loop: true,
        },
      })
      .then((response) => {
        const jobId = response.data.job_id;

        const interval = setInterval(async () => {
          const jobResponse = await api.get(`/rounds/grouped/job/${jobId}`);
          if (jobResponse.data) {
            clearInterval(interval);
            setReport(jobResponse.data);
            let tableColumns = 0;
            jobResponse.data.forEach((day) => {
              if (day.result.length > tableColumns) {
                tableColumns = day.result.length;
              }
            });
            setLoading(false);
            setMaximumColumns(tableColumns);
          }
        }, 5000);
      });
  }

  function getMissingColumns(value) {
    if (!maximumColumns || maximumColumns < value) return 0;
    return maximumColumns - value;
  }

  function handleCheckboxChange() {
    setChecked(!checked);
  }

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          Relatório por filtro
        </Typography>
        <Paper>
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Selecione o filtro
                  </InputLabel>

                  <Select
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    {filters.map((f) => (
                      <MenuItem key={f.id} value={f}>
                        {f.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="datetime-local"
                  label="Data de início"
                  type="datetime-local"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="datetime-local"
                  label="Data fim"
                  type="datetime-local"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFilter}
                >
                  Filtrar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckboxChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Resultados agrupados por hora"
                />
              </Grid>
            </Grid>
          </Container>
        </Paper>
        <Box mt={5}>
          {loading ? (
            <p>Carregando...</p>
          ) : (
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  {report.length > 0 && (
                    <TableRow>
                      <TableCell>
                        <strong>Data</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Giros</strong>
                      </TableCell>
                      {[...Array(maximumColumns)].map((_, i) => (
                        <TableCell key={_}>
                          <strong>{i + 1}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  {report.map((row) => (
                    <TableRow key={row.date}>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>{row.rounds}</TableCell>
                      {row.result.map((result) => (
                        <TableCell key={result.key}>{result.value}</TableCell>
                      ))}
                      {Array(getMissingColumns(row.result.length))
                        .fill()
                        .map(() => (
                          <TableCell>-</TableCell>
                        ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Container>
    </>
  );
}

export default ReportV2;
