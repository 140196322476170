import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup.string().required('Obrigatório'),
  commercial_name: Yup.string().required('Obrigatório'),
  risk: Yup.string().required('Obrigatório'),
  best_scenario: Yup.string().required('Obrigatório'),
  worst_scenario: Yup.string().required('Obrigatório'),
  expected_scenario: Yup.string().required('Obrigatório'),
  recommended_capital: Yup.string().required('Obrigatório'),
  robot_cost: Yup.string().required('Obrigatório'),
  infra_cost: Yup.string().required('Obrigatório'),
  gain_goal: Yup.string().nullable(),
  monthly_gain_goal: Yup.string().nullable(),
  loss_limit: Yup.string().nullable(),
  monthly_loss_limit: Yup.string().nullable(),
  minimum_days: Yup.string().required('Obrigatório'),
  percent_upon_profit: Yup.string().required('Obrigatório'),
  roulette_name: Yup.string().required('Obrigatório'),
  roulette_link: Yup.string().required('Obrigatório'),
  stripe_product_id: Yup.string(),
  entry: Yup.number().required('Obrigatório'),
  base_bet_amount: Yup.string().required('Obrigatório'),
  delay: Yup.string(),
  observation: Yup.string(),
  last_year_income: Yup.string().required('Obrigatório'),
  last_year_assertiveness_rate: Yup.string().required('Obrigatório'),
  steps: Yup.array().of(
    Yup.object().shape({
      path_id: Yup.number().typeError('Obrigatório').required('Obrigatório'),
      clicks: Yup.number()
        .typeError('Precisa ser um número válido')
        .positive('Precisa ser um número positivo')
        .required('Obrigatório'),
      order: Yup.number().required('Obrigatório'),
    })
  ),
  martingales: Yup.array().of(
    Yup.object().shape({
      order: Yup.number().required('Obrigatório'),
      base_bet_amount: Yup.string().required('Obrigatório'),
      steps: Yup.array().of(
        Yup.object().shape({
          path_id: Yup.number()
            .typeError('Obrigatório')
            .required('Obrigatório'),
          clicks: Yup.number()
            .typeError('Precisa ser um número válido')
            .positive('Precisa ser um número positivo')
            .required('Obrigatório'),
          order: Yup.number().required('Obrigatório'),
        })
      ),
    })
  ),
});
