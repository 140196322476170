import {
  Container,
  Typography,
  Paper,
  Grid,
  MenuItem,
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Chip,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { Select } from 'unform-material-ui';
import { Scope } from '@unform/core';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import { prop, sortBy } from 'ramda';
import api from '../../services/api';
import Input from '../../components/Input';
import CurrencyInput from '../../components/CurrencyInput';
import { calculatorResultStyles } from '../../utils/styles';
import getGrouppedDates from '../../utils/getGrouppedDates';
import { currencyFormat } from '../../utils/formatter';
import { getTotalFromPlay } from '../../utils/functions';

function StrategyCalculatorV3() {
  const classes = calculatorResultStyles();
  const formRef = useRef(null);
  const [start_date, setStartDate] = React.useState(null);
  const [end_date, setEndDate] = React.useState(null);
  const [entryRows, setEntryRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [fasterAlgorithm, setFasterAlgorithm] = useState(false);
  const [totalWinss, setTotalWinss] = useState(0);
  const [totalLoss, setTotalLoss] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [periods, setPeriods] = useState([]);

  function createEntryRows() {
    const { entry, end } = formRef.current.getData();
    if (entry && end) {
      const arraySize = Number(end) - Number(entry);
      const array = Array.from({ length: arraySize }, (v, k) => ({
        id: k + 1,
        filters: [],
      }));
      setEntryRows(array);
    } else {
      setEntryRows([]);
    }
  }

  async function onSubmit(data) {
    if (!start_date || !end_date) {
      // eslint-disable-next-line
      alert('Informe as datas de início e fim');
      return;
    }

    const schema = Yup.object().shape({
      entry: Yup.string().required('Obrigatório'),
      end: Yup.string().required('Obrigatório'),
      entry_1: Yup.string().required('Obrigatório'),
      gain_goal: Yup.string().nullable(),
      loss_limit: Yup.string().nullable(),
      monthly_gain_goal: Yup.string().nullable(),
      monthly_loss_limit: Yup.string().nullable(),
      group_by: Yup.string().required('Obrigatório'),
      dozens_or_columns: Yup.string().required('Obrigatório'),
      start_hour: Yup.string(),
      end_hour: Yup.string(),
      loss: Yup.number()
        .typeError('Precisa ser um número válido')
        .required('Obrigatório'),
      rows: Yup.array().of(
        Yup.object().shape({
          amount: Yup.number()
            .typeError('Precisa ser um número válido')
            .required('Obrigatório'),
        })
      ),
      periods: Yup.array().of(
        Yup.object().shape({
          start: Yup.string().required('Obrigatório'),
          end: Yup.string().required('Obrigatório'),
          goal: Yup.string().nullable().required('Obrigatório'),
          loss_goal: Yup.string().nullable().required('Obrigatório'),
        })
      ),
    });

    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      const grouppedDates = getGrouppedDates({
        group_by: data.group_by,
        start_date,
        end_date,
      });

      setLoading(true);

      const result = [];

      let promiseCount = 0;

      await Promise.all(
        grouppedDates.map(async (date) => {
          const payload = {
            ...data,
            start_date: date.start_date,
            end_date: date.end_date,
            faster_algorithm: fasterAlgorithm,
          };

          const response = await api.post('/admin/calculator/v3', payload);
          const jobId = response.data.job_id;

          const interval = setInterval(async () => {
            const jobResponse = await api.get(
              `/admin/calculator/v3/job/${jobId}`
            );
            if (jobResponse.data) {
              promiseCount += 1;
              clearInterval(interval);
              result.push(jobResponse.data);
              const sortByDate = sortBy(prop('start_date'));
              setReports(sortByDate(result));
              const sum = (a, b) => a + b;
              if (promiseCount === grouppedDates.length) {
                setTotalWinss(
                  result
                    .map((r) => {
                      if (r.totalWins) {
                        return r.totalWins.events;
                      }
                      return 0;
                    })
                    .reduce(sum, 0)
                );
                setTotalLoss(
                  result
                    .map((r) => {
                      if (r.totalLoss) {
                        return r.totalLoss.events;
                      }
                      return 0;
                    })
                    .reduce(sum, 0)
                );
                setTotalBalance(
                  result.map((r) => r.totalBalance || 0).reduce(sum, 0)
                );
                setLoading(false);
              }
            }
          }, 5000);
        })
      );
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        console.log(validationErrors);
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function handleCheckboxFasterAlgorithmChange() {
    setFasterAlgorithm(!fasterAlgorithm);
  }

  function addNewPeriod() {
    setPeriods((oldValue) => [
      ...oldValue,
      {
        id: Math.random(),
      },
    ]);
  }

  function removePeriod(periodId) {
    const newPeriods = periods.filter((period) => period.id !== periodId);
    setPeriods(newPeriods);
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
        Calculadora de Estratégias V3
      </Typography>
      <Form onSubmit={onSubmit} ref={formRef} noValidate>
        <Paper className={classes.paper}>
          <h2>Detalhes do filtro</h2>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Select
                label="Agrupar saldo por"
                name="group_by"
                style={{ width: '100%' }}
                defaultValue="month"
              >
                <MenuItem value="month">Mês</MenuItem>
                <MenuItem value="two_months">Bimestre</MenuItem>
                <MenuItem value="three_months">Trimestre</MenuItem>
                <MenuItem value="six_months">Semestre</MenuItem>
                <MenuItem value="year">Ano</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="datetime-local"
                label="Data de início"
                type="datetime-local"
                fullWidth
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="datetime-local"
                label="Data fim"
                type="datetime-local"
                fullWidth
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => setEndDate(e.target.value)}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <h2>Detalhes da estratégia</h2>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Input
                required
                id="entry"
                name="entry"
                label="Entrada"
                onChange={(e) => {
                  const result = Number(e.target.value) + 1;
                  formRef.current.setFieldValue('entry_1', result);
                  createEntryRows();
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                disabled
                id="entry"
                name="entry_1"
                label="Entra no"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                required
                id="end"
                name="end"
                label="Vai até"
                fullWidth
                onChange={() => createEntryRows()}
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                thousandSeparator="."
                decimalSeparator=","
                required
                id="gain_goal"
                name="gain_goal"
                label="Meta diária"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                thousandSeparator="."
                decimalSeparator=","
                required
                id="loss_limit"
                name="loss_limit"
                label="Limite de perda diária"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                thousandSeparator="."
                decimalSeparator=","
                required
                id="monthly_gain_goal"
                name="monthly_gain_goal"
                label="Meta mensal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                thousandSeparator="."
                decimalSeparator=","
                required
                id="monthly_loss_limit"
                name="monthly_loss_limit"
                label="Limite de perda mensal"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                required
                type="time"
                id="start_hour"
                name="start_hour"
                label="Hora de início"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                required
                type="time"
                id="end_hour"
                name="end_hour"
                label="Hora de parada"
                fullWidth
              />
            </Grid>
            {periods.length > 0 && (
              <Grid item xs={12} style={{ marginBottom: '-35px' }}>
                <h2>Horários dinâmicos</h2>
              </Grid>
            )}
            {periods.map((period, i) => (
              <Scope key={period.id} path={`periods[${i}]`}>
                <Grid item xs={3}>
                  <Input
                    type="time"
                    id="start"
                    name="start"
                    label="Hora de início"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    type="time"
                    id="end"
                    name="end"
                    label="Hora fim"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <CurrencyInput
                    thousandSeparator="."
                    decimalSeparator=","
                    required
                    id="goal"
                    name="goal"
                    label="Meta de Ganho"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <CurrencyInput
                    thousandSeparator="."
                    decimalSeparator=","
                    required
                    id="loss_goal"
                    name="loss_goal"
                    label="Limite de Perda"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box mt={1} variant>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => removePeriod(period.id)}
                    >
                      Excluir
                    </Button>
                  </Box>
                </Grid>
              </Scope>
            ))}
            <Grid item xs={12}>
              <Box variant>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => addNewPeriod()}
                >
                  + Horários
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                style={{ marginTop: '5px' }}
                control={
                  <Checkbox
                    checked={fasterAlgorithm}
                    onChange={handleCheckboxFasterAlgorithmChange}
                    color="primary"
                  />
                }
                label="Algorítmo rápido"
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Análise de dúzias ou colunas"
                name="dozens_or_columns"
                style={{ width: '100%' }}
                defaultValue="dozens"
              >
                <MenuItem value="dozens">Dúzias</MenuItem>
                <MenuItem value="columns">Colunas</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Paper>
        <Box mt={3}>
          <Paper className={classes.paper}>
            <h2>Detalhes financeiros da estratégia</h2>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <Box mt={2}>
                  <Chip
                    label="LOSS"
                    variant="outlined"
                    size="medium"
                    color="primary"
                  />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <CurrencyInput
                  thousandSeparator="."
                  decimalSeparator=","
                  required
                  name="loss"
                  label="Valor loss total"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
        {entryRows.map((row, i) => (
          <Box mt={3} key={row.id}>
            <Paper className={classes.paper}>
              <Scope key={row} path={`rows[${i}]`}>
                <Grid item xs={2}>
                  <Chip
                    label={`${i + 1}ª entrada (${
                      Number(formRef.current.getFieldValue('entry_1')) + i
                    })`}
                    variant="outlined"
                    size="medium"
                    color="primary"
                  />
                </Grid>
                <Grid item xs={2} style={{ margin: '10px 0' }}>
                  <CurrencyInput
                    thousandSeparator="."
                    decimalSeparator=","
                    required
                    name="amount"
                    label="Lucro líquido"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Scope>
            </Paper>
          </Box>
        ))}
        <Grid item xs={3} style={{ marginTop: '30px' }}>
          <Button variant="outlined" color="primary" type="submit">
            Calcular agora
          </Button>
        </Grid>

        {loading ? (
          <Box mt={2}>
            <span>Carregando...</span>
          </Box>
        ) : (
          <div className="section-to-print">
            {reports.length > 0 && !loading && (
              <Box mt={2} style={{ border: '5px solid gray' }}>
                <Paper className={classes.paper}>
                  <h2>
                    Totais de {format(parseISO(start_date), 'dd/MM/yyyy')} até{' '}
                    {format(parseISO(end_date), 'dd/MM/yyyy')}
                  </h2>
                  <div className={classes.resultContainer}>
                    <div className={classes.resultBox}>
                      <span>Wins</span>
                      <span>{totalWinss}</span>
                    </div>
                    <div className={classes.resultBox}>
                      <span>Saldo Final</span>
                      <span>{currencyFormat(totalBalance)}</span>
                    </div>
                    <div className={classes.resultBox}>
                      <span>Loss</span>
                      <span>{totalLoss}</span>
                    </div>
                  </div>
                </Paper>
              </Box>
            )}
            {reports.map((report) => (
              <>
                <Box mt={2}>
                  <Paper className={classes.paper}>
                    <h2>
                      Resultado do período de{' '}
                      {format(parseISO(report.start_date), 'dd/MM/yyyy')} até{' '}
                      {format(parseISO(report.end_date), 'dd/MM/yyyy')}
                    </h2>
                    {report.rows && report.rows.length > 0 ? (
                      <div className={classes.resultContainer}>
                        <div className={classes.resultBox}>
                          <span>Wins</span>
                          <span>{report.totalWins.events}</span>
                        </div>
                        <div className={classes.resultBox}>
                          <span>Saldo Final</span>
                          <span>{currencyFormat(report.totalBalance)}</span>
                        </div>
                        <div className={classes.resultBox}>
                          <span>Loss</span>
                          <span>{report.totalLoss.events}</span>
                        </div>
                      </div>
                    ) : (
                      <h3>O robô de coleta ainda não operava neste período.</h3>
                    )}
                  </Paper>
                </Box>
                <Box mt={2}>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        {report && report.rows && report.rows.length > 0 && (
                          <TableRow>
                            <TableCell align="center">
                              <strong>Data</strong>
                            </TableCell>
                            {[...Array(report.rows[0].plays.length)].map(
                              (_, i) => (
                                <TableCell key={_} align="center">
                                  <strong>
                                    {Number(
                                      formRef.current.getFieldValue('entry_1')
                                    ) + i}
                                  </strong>
                                </TableCell>
                              )
                            )}
                            <TableCell align="center">
                              <strong>Wins</strong>
                            </TableCell>
                            <TableCell align="center">
                              <strong>Loss</strong>
                            </TableCell>
                            <TableCell align="center">
                              <strong>Total do dia</strong>
                            </TableCell>
                            <TableCell align="center">
                              <strong>Saldo</strong>
                            </TableCell>
                            <TableCell align="center">
                              <strong>Maior negativação</strong>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableHead>
                      <TableBody>
                        {report &&
                          report.rows &&
                          report.rows.map((row) => (
                            <TableRow key={row.formattedDate}>
                              <TableCell align="center">
                                {row.formattedDate}
                              </TableCell>
                              {row.plays.map((play) => (
                                <TableCell key={play.key} align="center">
                                  <strong>{play.value}</strong> <br />{' '}
                                  <span
                                    style={{
                                      color: Number(play.amount) < 0 && 'red',
                                    }}
                                  >
                                    {currencyFormat(play.amount)}
                                  </span>
                                </TableCell>
                              ))}
                              <TableCell align="center">
                                <strong>{row.wins.qtd}</strong> <br />{' '}
                                {currencyFormat(row.wins.amount)}
                              </TableCell>
                              <TableCell align="center">
                                <strong>{row.loss.qtd}</strong> <br />{' '}
                                {currencyFormat(row.loss.amount)}
                              </TableCell>
                              <TableCell align="center">
                                {currencyFormat(row.todaysBalance)}
                              </TableCell>
                              <TableCell align="center">
                                <span
                                  style={{
                                    color: row.balance < 0 ? 'red' : 'green',
                                  }}
                                >
                                  {currencyFormat(row.balance)}
                                </span>
                              </TableCell>
                              <TableCell align="center">
                                <span
                                  style={{
                                    color:
                                      row.biggestNegativeBalance < 0
                                        ? 'orange'
                                        : 'gray',
                                  }}
                                >
                                  {currencyFormat(row.biggestNegativeBalance)}
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                        {report && report.rows && (
                          <TableRow>
                            <TableCell align="center">
                              <strong>Totais</strong>
                            </TableCell>
                            {getTotalFromPlay(
                              report.rows,
                              report.entry,
                              report.end
                            ).map((item) => (
                              <TableCell align="center">
                                <strong>{item.totalEvents}</strong> (
                                {currencyFormat(item.total)})
                              </TableCell>
                            ))}
                            <TableCell align="center">
                              <strong>{report.totalWins.events}</strong> (
                              {currencyFormat(report.totalWins.total)})
                            </TableCell>
                            <TableCell align="center">
                              <strong>{report.totalLoss.events}</strong> (
                              {currencyFormat(report.totalLoss.total)})
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </>
            ))}
          </div>
        )}
      </Form>
    </Container>
  );
}

export default StrategyCalculatorV3;
