import api from '../services/api';

const handleCreateInstance = async ({ robot_id, table_name }) => {
  await api.post(`admin/jenkins/create-instance`, {
    robot_id,
    table_name,
  });
  alert('Instância criada com sucesso!'); // eslint-disable-line
};

const handleTerminateInstance = async ({ robot_id, table_name }) => {
  await api.post(`admin/jenkins/terminate-instance`, {
    robot_id,
    table_name,
  });
  alert('Instância terminada com sucesso!'); // eslint-disable-line
};

const handleRestartProject = async ({ robot_id, table_name }) => {
  await api.post(`admin/jenkins/restart-project`, {
    robot_id,
    table_name,
  });
  alert('Projeto reiniciado com sucesso!'); // eslint-disable-line
};

const handleUpdateProject = async ({ robot_id, table_name }) => {
  await api.post(`admin/jenkins/update-project`, {
    robot_id,
    table_name,
  });
  alert('Projeto atualizado com sucesso!'); // eslint-disable-line
};

export {
  handleCreateInstance,
  handleTerminateInstance,
  handleRestartProject,
  handleUpdateProject,
};
