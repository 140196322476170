const {
  addMonths,
  differenceInMonths,
  endOfMonth,
  startOfMonth,
} = require('date-fns');

const { parseISO } = require('date-fns');

const getGrouppedDates = ({ group_by, start_date, end_date }) => {
  const diffInMonths = () =>
    differenceInMonths(parseISO(end_date), parseISO(start_date));

  const differenceInBimesters = () => Math.floor(diffInMonths() / 2);

  const differenceInTrimesters = () => Math.floor(diffInMonths() / 3);

  const differenceInSemesters = () => Math.floor(diffInMonths() / 6);

  const differenceInYears = () => Math.floor(diffInMonths() / 12);

  const result = [];

  const monthDiff = diffInMonths();

  if (group_by === 'month') {
    const array = Array.from({ length: monthDiff + 1 }, (v, k) => k + 1);
    let date = parseISO(start_date);
    array.forEach(() => {
      result.push({
        start_date: startOfMonth(date),
        end_date: endOfMonth(date),
      });
      date = addMonths(date, 1);
    });
  } else if (group_by === 'two_months') {
    const diffInBimesters = differenceInBimesters();
    const array = Array.from({ length: diffInBimesters + 1 }, (v, k) => k + 1);
    let date = parseISO(start_date);
    array.forEach(() => {
      result.push({
        start_date: startOfMonth(date),
        end_date: endOfMonth(addMonths(date, 1)),
      });
      date = addMonths(date, 2);
    });
  } else if (group_by === 'three_months') {
    const diffInTrimesters = differenceInTrimesters();
    const array = Array.from({ length: diffInTrimesters + 1 }, (v, k) => k + 1);
    let date = parseISO(start_date);
    array.forEach(() => {
      result.push({
        start_date: startOfMonth(date),
        end_date: endOfMonth(addMonths(date, 2)),
      });
      date = addMonths(date, 3);
    });
  } else if (group_by === 'six_months') {
    const diffInSemesters = differenceInSemesters();
    const array = Array.from({ length: diffInSemesters + 1 }, (v, k) => k + 1);
    let date = parseISO(start_date);
    array.forEach(() => {
      result.push({
        start_date: startOfMonth(date),
        end_date: endOfMonth(addMonths(date, 5)),
      });
      date = addMonths(date, 6);
    });
  } else if (group_by === 'year') {
    const diffInYears = differenceInYears();
    const array = Array.from({ length: diffInYears + 1 }, (v, k) => k + 1);
    let date = parseISO(start_date);
    array.forEach(() => {
      result.push({
        start_date: startOfMonth(date),
        end_date: endOfMonth(addMonths(date, 11)),
      });
      date = addMonths(date, 12);
    });
  } else {
    result.push({
      start_date,
      end_date,
    });
  }

  result[0].start_date = start_date;
  result[result.length - 1].end_date = end_date;

  return result;
};

module.exports = getGrouppedDates;
